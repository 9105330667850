import React, { Fragment } from "react";
import { Container } from "react-bootstrap";

export default function ContainerWithHeader({
  title,
  children,
  useToc,
  titleClassName = "",
  contentClassName = "",
}) {
  return (
    <Fragment>
      <Container
        className={`d-flex justify-content-center bg-dark ${titleClassName}`}
        style={{height: 60}}
        fluid
      >
        <h3
          className="text-light d-flex align-items-center justify-content-center"
          style={{
            textTransform: "capitalize",
          }}
        >
          {title}
        </h3>
      </Container>
      <Container className={`my-5 ${contentClassName}`}>{children}</Container>
    </Fragment>
  );
}
