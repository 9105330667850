import React, { useContext, useEffect, useState } from 'react';
import { LoginModal } from '../../components/login/login-modal';
import SiteContext from '../../utils/site-context';

export default function Signin({ location, history }) {

  const { user } = useContext(SiteContext);
  let [from] = useState(() => {
    if (location && location.state && location.state.from) return location.state.from;
    return { pathname: "/" }
  });

  useEffect(() => {
    if (!user.isGuest)
      history.replace(from)
  }, [user, from, history]);

  return (
    <div>
      <LoginModal showLoginModal={true} setShowLoginModal={() => { }} from={from} />
    </div>
  )
}