import { format } from "date-fns";
import formToObject from "form-to-object";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardColumns,
  Form,
  Modal
} from "react-bootstrap";
import ContainerWithHeader from "../../components/ContainerWithHeader";
import Loading from "../../components/loading";
import { AlgoTable } from "../../learn/algo/algo-index";
import { pipelines } from "../../pages/frontpage/course-data";
import StudentClassRecords from "../../student/components/student-class-records";
import { firebase, fstore, Timestamp } from "../../utils/fb";
import { yearToGrade } from "../../utils/my-date-fns";
import { updateField, updateFieldPrompt } from "../../utils/my-lodash";
import SiteContext from "../../utils/site-context";
import { GridTable } from "../../utils/styled-elements";

export default function AdminStudent({ match }) {
  const { user } = useContext(SiteContext);
  const [student, setStudent] = useState(undefined);

  useEffect(() => {
    fstore.collection("students").doc(match.params.uid).get().then(studentDoc => {
      setStudent(studentDoc);
    });
  }, [match])

  async function onFieldClick(field, isDetail) {
    updateFieldPrompt({ collectionName: "students", doc: student, field, isDetail }).then((student) => {
      if (student) setStudent(student);
    })
  }

  if (!student) return <Loading className={"fullscreen-centered"} />;

  return (
    <ContainerWithHeader title={"🎓 " + student.data().name} contentClassName="mt-3 mb-5">
      {user.isSuperAdmin && <StudentInfo student={student} setStudent={setStudent} onFieldClick={onFieldClick} />}
      <Enrollment student={student} setStudent={setStudent} />
      <Card className="mt-3 mb-3">
        <Card.Header>Algo Submissions (Last 14 days)</Card.Header>
        <Card.Body>
          <AlgoTable student={student} />
        </Card.Body>
      </Card>

      <StudentClassRecords student={student} />
      <Notes
        studentId={student.id}
        colRef={fstore
          .collection("students")
          .doc(student.id)
          .collection("internal-memo")}
        title="⚠ Internal Memos"
      />

      {user.isSuperAdmin && (
        <>
          <h3 className="text-center mt-5 border-top border-bottom p-3">
            Super Admin Only
          </h3>

        </>
      )}
    </ContainerWithHeader>
  );
}

//region student-info
function StudentInfo({ student, setStudent, onFieldClick }) {
  let { email, name, /*sid,*/ phone, is_active, /*login_id, */ login_id2, detail } = student.data();
  const [loading, setLoading] = useState(false);

  const createLoginAct = () => {
    window.alert("Login Account Cannot Be Changed")
    let login_id2 = window.prompt("Enter Desired Login Account");

    if (login_id2 === null) return;
    setLoading(true);
    fstore.collection("students").where("login_id2", "==", login_id2).get().then(ss => ss.docs).then(docs => {
      if (docs.length > 0) {
        window.alert("This user id already exists");
        setLoading(false);
      } else {
        fstore.collection("students").doc(student.id).update({ login_id2 })
          .then(() => {
            setStudent({ id: student.id, data: () => ({ ...student.data(), login_id2 }) });
            setLoading(false);
          })
      }
    })
  }

  const deleteLoginAct = () => {
    alert("deleting a login does not delete a user. you need to do that manually");
    fstore.collection("students").doc(student.id).update({ login_id2: firebase.firestore.FieldValue.delete() })
  }

  return (
    <Card className="mt-3 mb-3">
      <Card.Header>
        <div>
          Profile of <strong>{name}</strong> (Grade {yearToGrade(detail.graduation_year)})
        </div>
      </Card.Header>

      <Card.Body>
        <GridTable numCols={4} className="mb-3">
          <div className="font-weight-bold">Name</div>
          <div className="highlight-onhover" onClick={() => onFieldClick('name')}>{name}</div>
          {/* <div className="font-weight-bold">Email</div>
          <div onClick={() => onFieldClick('email')}>{email}</div> */}

          <div className="font-weight-bold">Login Id*</div>
          <div>
            <span>{login_id2}</span>
            <span className="ml-2">
              {login_id2 ?
                <Button size="sm" onClick={deleteLoginAct} disabled={loading}>Delete</Button>
                : <Button size="sm" onClick={createLoginAct} disabled={loading}>Create</Button>}
            </span>
          </div>
          <div className="font-weight-bold">Phone</div>
          <div className="highlight-onhover" onClick={() => onFieldClick('phone')}>{phone}</div>
          <div className="font-weight-bold">Email</div>
          <div className="highlight-onhover" onClick={() => onFieldClick('email')}>{email}</div>
          <div className="span-row" />

          {"address,contact_method,father_name,mother_name,guardian_email,guardian_phone,graduation_year,memo,school".split(",").map((col) => (
            <Fragment key={col}>
              <div className="font-weight-bold">{col.replace("_", " ")}</div>
              <div className="highlight-onhover" onClick={() => onFieldClick(col, true)}>{detail[col]}</div>
            </Fragment>
          ))}

          <div className="span-row" />

          {"trial_date,start_date,quit_date".split(",").map((col) => (
            <Fragment key={col}>
              <div className="font-weight-bold">{col.replace("_", " ")}</div>
              <div className="highlight-onhover" onClick={() => onFieldClick(col, true)}>{detail[col]}</div>
            </Fragment>
          ))}
          <div className="font-weight-bold">Active</div>
          <div className="highlight-onhover" onClick={() => onFieldClick('is_active')}>{is_active ? 'true' : 'false'}</div>

          <div className="span-row" />

          {/* <div className="font-weight-bold">SID (old site)</div>
          <div onClick={() => onFieldClick('sid')}>{sid}</div> */}

        </GridTable>
        <Alert variant="info">
          Default password for a student account is <code>changemenow</code><br />
          Default password for a parent account is <code>changeme</code>
        </Alert>
      </Card.Body>
    </Card>
  );
}
//endregion


//region notes
function Notes({ colRef, title }) {
  const [memos, setMemos] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const { user } = useContext(SiteContext);
  const { displayName, isSuperAdmin } = user;

  useEffect(() => {
    colRef
      .get()
      .then((ss) => ss.docs)
      .then(setMemos);
  }, [colRef]);

  async function addMemo(event) {
    event.preventDefault();
    let formData = formToObject(event.target);

    let data = {
      ...formData,
      by: displayName,
      // date: new firestore.Timestamp.now(),
      date: new Timestamp.now(),
    };

    let docRef = await colRef.add(data);

    setMemos((prev) => [...prev, { id: docRef.id, data: () => data }]);
    setShowForm(false);
  }

  async function deleteMemo(id) {
    if (!window.confirm("Are you sure?")) return;
    colRef.doc(id).delete();
    setMemos(memos.filter((m) => m.id !== id));
  }

  return (
    <>
      <Card className="mt-3 mb-3">
        <Card.Header>{title}</Card.Header>
        <Card.Body>
          <CardColumns>
            {memos.map((m, i) => (
              <Card key={i}>
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <span>{format(m.data().date.toDate(), "yyyy-MM-dd")}</span>
                  <span>
                    {m.data().by && <Badge variant="dark">{m.data().by}</Badge>}
                    {isSuperAdmin && (
                      <span
                        className="pointer"
                        role="img"
                        aria-label=""
                        onClick={() => deleteMemo(m.id)}
                      >
                        ❌
                      </span>
                    )}
                  </span>
                </Card.Header>
                <Card.Body>
                  <Card.Text>{m.data().text}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </CardColumns>
          <Button onClick={() => setShowForm(true)}>+ ADD</Button>
        </Card.Body>
      </Card>
      <Modal
        show={showForm}
        onHide={() => setShowForm(false)}
        centered
        size="xl"
      >
        <Modal.Body>
          <Form onSubmit={addMemo}>
            <Form.Group>
              <Form.Label>Report</Form.Label>
              <Form.Control as="textarea" rows={3} name="text" />
            </Form.Group>
            <Button type="submit">ADD</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
//endregion


function Enrollment({ student, setStudent }) {

  let [enrolled, setEnrolled] = useState(student.data().detail.enrolled || []);
  let [loading, setLoading] = useState(false);

  const toggleEnrol = (cid) => {
    if (!enrolled.includes(cid))
      setEnrolled([...enrolled, cid])
    else
      setEnrolled(enrolled.filter(v => v !== cid));
  }
  const save = async () => {
    setLoading(true);
    let doc = await updateField({ collectionName: "students", doc: student, field: "enrolled", value: enrolled, isDetail: true })
    setStudent(doc)
    setLoading(false);
  }

  return (
    <Card className="mb-3">
      <Card.Header className="d-flex justify-content-between">
        <div>Enrolled Courses</div>
        <div>
          <Button size="sm" disabled={loading} onClick={save}>{loading ? "loading" : "save"}</Button>
        </div>
      </Card.Header>
      <Card.Body>
        {
          pipelines.map(p => <div key={p.name}>
            <div>{p.name}</div>
            <div>
              <ButtonGroup>
                {
                  p.courses.map((course, i) => <Button
                    variant={enrolled.includes(course.id) ? "primary" : "outline-primary"}
                    key={course.id || i} onClick={() => toggleEnrol(course.id)}
                    disabled={!course.id}
                  >{course.name}</Button>)
                }
              </ButtonGroup>
            </div>
          </div>)
        }
      </Card.Body>
    </Card>
  )
}