import React, { useContext, useEffect, useState } from 'react';
import { Breadcrumb, Button, ButtonGroup } from 'react-bootstrap';
import { FaPencilAlt, FaTv } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import MDEditorLite from "react-markdown-editor-lite";
import 'react-markdown-editor-lite/lib/index.css';
import MathJax from 'react-mathjax';
import { LinkContainer } from "react-router-bootstrap";
import RemarkMathPlugin from 'remark-math';
import Loading from '../../components/loading';
import CodeBlock from '../../components/syntax-highliter/codeblock';
import { fstore, fupload } from '../../utils/fb';
import SiteContext from '../../utils/site-context';
import Exercise from '../exercise-component/exercise';
import "./notes-rich-editor-lite.scss";

const CHAPTERS = fstore.collection("course-chapters");

export default function NotesrichEditorLite({ match }) {
  const [, courseName, cid] = /(.*)-([^-]*)/.exec(match.params.cid);
  const [, chname, chid] = /(.*)-([^-]*)/.exec(match.params.chid);
  const imgPath = `${courseName}/${chname}`;
  const { user } = useContext(SiteContext);

  // from DB
  const [chapter, setChapter] = useState(undefined);
  const [notes, setNotes] = useState(undefined);
  const [submissions, setSubmissions] = useState([]);
  const [ptmode, setPtmode] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const { adminMode } = useContext(SiteContext);

  async function updateNoteText(text) {
    await CHAPTERS.doc(chid).collection("materials").doc("notes").set({ text });
    setNotes(text);
  }

  async function addSubmission(formData) {

    let data = {
      uid: user.uid,
      timestamp: new Date(),
      chid,
      detail: { ...formData, courseName, chname }
    }

    let { id } = await fstore.collection("code-submissions").add(data);
    let doc = await fstore.collection("code-submissions").doc(id).get();
    setSubmissions(v => [...v, doc]);
    return;
  }

  useEffect(() => {
    const [, , chid] = /(.*)-([^-]*)/.exec(match.params.chid);
    CHAPTERS.doc(chid).get().then(setChapter).catch(() => console.log(1));
    CHAPTERS.doc(chid).collection("materials").doc("notes").get().then(d => {
      if (d.exists) setNotes(d.data().text);
      else setNotes('empty');
    }).catch(console.log);
    // fstore.collection("code-submissions")
    //   .where('uid', '==', user.uid)
    //   .where('chid', '==', chid)
    //   .get().then(s => s.docs).then(setSubmissions).catch(() => console.log(3));
  }, [match.params.chid, user]);

  const loaded = chapter && notes;

  if (!loaded) return <Loading text="Loading..." />

  return <div className="NotesRichEditorLite" key={chid}>
    <Breadcrumb>
      <LinkContainer to={`/learn/course/${courseName}-${cid}`}>
        <Breadcrumb.Item>{courseName} Home</Breadcrumb.Item>
      </LinkContainer>
      <Breadcrumb.Item active>{chapter.data().name}</Breadcrumb.Item>
    </Breadcrumb>

    {adminMode && (
      <div className="d-flex justify-content-end">
        <ButtonGroup>
          <Button variant={`${editMode ? "primary" : "outline-primary"}`} onClick={() => setEditMode(v => !v)}>
            <FaPencilAlt />
          </Button>
          <Button variant={`${ptmode ? "primary" : "outline-primary"}`} onClick={() => setPtmode(v => !v)}>
            <FaTv />
          </Button>
        </ButtonGroup>
      </div>
    )}

    {
      editMode ? <NoteEditor imgPath={imgPath} key={chid} defaultText={notes} updateNoteText={updateNoteText} />
        : (<MathJax.Provider input="tex">
          <ReactMarkdown source={notes} renderers={{
            code: CodeBlock,
            math: (props) => <MathJax.Node formula={props.value} />,
            inlineMath: (props) => <MathJax.Node inline formula={props.value} />
          }} escapeHtml={false}
            plugins={[RemarkMathPlugin]}
          />
        </MathJax.Provider>)
    }

    <Exercise addSubmission={addSubmission} submissions={submissions} />
  </div>
}

function NoteEditor({ defaultText, updateNoteText, imgPath }) {

  const [saving, setSaving] = useState(false);
  const [editorText, setEditorText] = useState(defaultText || 'empty');

  async function handleFileUpload(file) {
    const ref = fupload.ref(`slide/${imgPath}/${file.name}`);
    await ref.put(file);
    return await ref.getDownloadURL();
  }

  async function handleSave() {
    setSaving(true);
    await updateNoteText(editorText);
    setSaving(false);
  }

  function renderPreview(notes) {
    return (<MathJax.Provider input="tex">
      <ReactMarkdown source={notes} renderers={{
        math: (props) => <MathJax.Node formula={props.value} />,
        inlineMath: (props) => <MathJax.Node inline formula={props.value} />
      }} escapeHtml={false}
        plugins={[RemarkMathPlugin]}
      />
    </MathJax.Provider>)
  }

  return (
    <div className={`p-3 bg-white`}>

      <MDEditorLite
        value={editorText}
        style={{ height: "500px" }}
        renderHTML={(text) => renderPreview(text)}
        onChange={({ html, text }) => setEditorText(text)}
        onImageUpload={handleFileUpload}
        name="body"
      />

      <Button variant={saving ? "warning" : "success"} size="sm" onClick={handleSave} className="my-5">
        {saving ? "Saving..." : "Save"}
      </Button>

    </div>
  )
}

/*
function CodeSubmissions({ submissions }) {

  return (<><h3>Code submissions ({submissions.length})</h3>
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Time</th>
          <th>Score</th>
          <th>Comment</th>
          <th>Code</th>
        </tr>
      </thead>
      <tbody>
        {
          submissions.map(s => {
            let { name, score, comment } = s.data().detail; // type, code omitted
            let timestamp = s.data().timestamp;

            return (
              <tr key={s.id}>
                <td>{name}</td>
                <td>{format(timestamp.toDate(), "yyyy-MM-dd hh:mm aa")}</td>
                <td>{score}</td>
                <td>{comment}</td>
              </tr>
            )
          })
        }
      </tbody>
    </Table></>
  )
}*/




