interface User {
  isSuperAdmin: boolean;
  isAdmin: boolean;
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
}

class User {
  isSuperAdmin: boolean = false;
  isAdmin: boolean = false;
  uid: string = "";
  email: string = "";
  displayName: string = "Guest";
  photoURL: string = "";
  isGuest: boolean = false;

  constructor(obj: User) {
    Object.assign(this, obj);

    if (this.uid.length === 0) {
      this.isGuest = true;
    }
  }
}

export { User };
