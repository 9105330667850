import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import dark from "react-syntax-highlighter/dist/esm/styles/prism/a11y-dark";


export default function CodeBlock({ language, value }) {
  return (
    <SyntaxHighlighter language={language || "text"} useInlineStyles={true} style={dark}>
      {value}
    </SyntaxHighlighter>
  );
}