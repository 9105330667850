import React, { Fragment, useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaBookOpen, FaBug, FaChalkboardTeacher, FaChartLine, FaCode, FaHammer, FaUser, FaUserAlt } from "react-icons/fa";
import { Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { fauth } from "../../utils/fb";
import SiteContext from "../../utils/site-context";
import "./student-nav.scss";

export default function StudentNav() {
  const [collap, setCollap] = useState(
    localStorage.getItem("sidemenu-collapse") === "true"
  );
  const { setShowLoginModal, adminMode, setAdminMode, user } = useContext(SiteContext);

  useEffect(() => localStorage.setItem("sidemenu-collapse", collap), [collap]);
  if (!user) return <Fragment />

  return (
    <div className="StudentNav" style={{ width: collap ? "80px" : "270px" }}>
      <div className="fixedInner">
        <ProSidebar collapsed={collap}>
          <SidebarHeader className="text-center">
            <Link to="/">
              <img
                src="https://i.imgur.com/IuJpm6D.png"
                style={{ height: "30px" }}
                alt=""
              />
            </Link>
          </SidebarHeader>
          <SidebarContent style={{ overflowY: "auto" }}>
            <Menu iconShape="square" popperArrow={true}>
              <MenuItem icon={<FaChartLine />}>
                Dashboard
                <Link to="/learn"></Link>
              </MenuItem>
              <MenuItem icon={<FaChalkboardTeacher />}>
                Courses
                <Link to="/learn/courses"></Link>
              </MenuItem>
              {
                (user.isParent || user.isAdmin) ? (
                  <MenuItem icon={<FaBookOpen />}>
                    Parent Handbook
                    <Link to="/parent/handbook"></Link>
                  </MenuItem>
                ) : <Fragment />
              }


              {/* <MenuItem icon={<FaQuestion />}>Quiz Portal<Link to="/learn/quiz" /></MenuItem> */}
              {user.isAdmin ? <MenuItem icon={<FaBug />}>QnA<Link to="/learn/qna" /></MenuItem> : <Fragment />}
              {!user.isParent ? <MenuItem icon={<FaCode />}>Algo<Link to="/learn/algo" /></MenuItem> : <Fragment />}

              {user.uid ? (
                <SubMenu title={(user.student ? user.student.data().name : user.displayName)} icon={<FaUser />}>
                  <MenuItem>
                    Profile <Link to="/member/profile" />
                  </MenuItem>
                  <MenuItem onClick={() => fauth.signOut()}>Log Out</MenuItem>
                  {user.isSuperAdmin && (
                    <MenuItem>
                      <Form.Check
                        custom
                        type="switch"
                        label="Admin Mode"
                        checked={adminMode}
                        id="admin-mode-toggler"
                        onChange={(ev) => setAdminMode(ev.target.checked)}
                      />
                    </MenuItem>
                  )}
                </SubMenu>
              ) : (
                  <MenuItem icon={<FaUserAlt />} onClick={() => setShowLoginModal(true)}>
                    Login
                  </MenuItem>
                )}


              {
                user.isAdmin ? (
                  <MenuItem icon={<FaHammer />}>
                    Admin {user.isSuperAdmin ? <>{'😎'}</> : <Fragment />} <Link to="/admin" />
                  </MenuItem>
                ) : <Fragment />
              }
            </Menu>
          </SidebarContent>
          <SidebarFooter
            className="text-center pointer"
            onClick={() => setCollap((v) => !v)}
          >
            {collap ? <FaAngleDoubleRight /> : <FaAngleDoubleLeft />}
          </SidebarFooter>
        </ProSidebar>
      </div>
    </div >
  );
}