import Dexie from "dexie";
import { subMinutes } from "date-fns";

const DB_NAME: string = "id_cache";
const DB_VER: number = 1;

class IdCache {
  db: Dexie;

  constructor() {
    // @ts-ignore
    let db = (this.db = new Dexie(DB_NAME));
    db.version(DB_VER).stores({
      expiry: "id",
      students: "id",
      groups: "id",
      users: "id",
      timecards: "id",
    });
  }

  async get(
    storeName: string,
    getterFn: () => Promise<any[]>,
    {
      minutes = 60,
      forceRefresh = false,
    }: { minutes?: number; forceRefresh?: boolean } = {}
  ): Promise<any[]> {
    let meta = await this.db.table("expiry").get(storeName);

    if (
      !forceRefresh &&
      meta &&
      meta.date &&
      meta.date.getTime() >= subMinutes(new Date(), minutes).getTime()
    ) {
      console.log("✔ cache exists " + storeName);
      return this.db.table(storeName).toArray();
    } else {
      console.log("❌ no cache" + storeName);
      let docs = await getterFn();
      await this.set(storeName, docs);
      return docs;
    }
  }

  async set(storeName: string, data: any[]): Promise<any> {
    await this.db.table(storeName).clear();
    await this.db.table(storeName).bulkAdd(data);
    return this.db.table("expiry").put({ id: storeName, date: new Date() });
  }

  async updateItem(storeName: string, id: any, data: object): Promise<any> {
    await this.db.table(storeName).update(id, data);
    return this.db.table(storeName).get(id);
  }

  async deleteItem(storeName: string, id: string): Promise<any> {
    return this.db.table(storeName).delete(id);
  }

  async addItem(storeName: string, data: any): Promise<any> {
    return this.db.table(storeName).add(data);
  }
}

const idCache = new IdCache();

export default idCache;
