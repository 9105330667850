import React, { useContext, useState } from "react";
import { Alert } from "react-bootstrap";
import { Chart } from "react-google-charts";
import { useHistory } from 'react-router-dom';
import { pipelines } from "../../pages/frontpage/course-data";
import SiteContext from "../../utils/site-context";
import "./OrgChart.scss";


const DATA = [
  ["Name", "Manager", "Image", "Link"],
  ["Youth*", undefined],
  ...pipelines.slice(0, 1).reduce((p, c) => {
    return [...p, ...c.courses.map(v => [v.name, v.req || "Youth*", v.img, v.id])]
  }, [])
]

const DATA2 = [
  ["Name", "Manager", "Image", "Link"],
  ["Language*", undefined],
  ...pipelines.slice(1, 2).reduce((p, c) => {
    return [...p, ...c.courses.map(v => [v.name, v.req || "Language*", v.img, v.id])]
  }, [])
]

const DATA3 = [
  ["Name", "Manager", "Image", "Link"],
  ["Adnvaced Topics*", undefined],
  ...pipelines.slice(2).reduce((p, c) => {
    return [...p, ...c.courses.map(v => [v.name, v.req || "Adnvaced Topics*", v.img, v.id])]
  }, [])
]

export default function OrgCharts() {
  const { setShowLoginModal, user } = useContext(SiteContext);
  const [showLoginAlert, setShowLA] = useState(!!user.isGuest);

  return (
    <div className="OrgChart">
      
      <Alert variant="success mb-3">
        Previous and current enrolled classes are in <strong>green</strong>
      </Alert>

      <Alert
        variant="info"
        show={showLoginAlert}
        onClose={() => setShowLA(false)}
        dismissible
      >
        {"💡 "}
        <button className="btn-a" onClick={() => setShowLoginModal(true)}>
          Login
        </button>{" "}
        to view and track your progress
      </Alert>

      <div className="d-flex flex-wrap">
        <OrgChart data={DATA2} />
        <OrgChart data={DATA3} />
        <OrgChart data={DATA} />
      </div>

    </div>
  )
}

function OrgChart({ data }) {
  const { push } = useHistory();
  const { user } = useContext(SiteContext);

  let finished = ['Java', 'USACO Bronze'];
  finished = [];

  function handleSelect(row) {

    const [name, /*par*/, /*img*/, id] = data[row + 1];

    if (user.isAdmin && !!id)
      try {
        push(`/learn/course/${name.replace(" ", "-")}-${id}`);
      } catch (e) { }
  }

  const chartData = data.map(([name, par, img, id], i) => {
    if (i === 0) return ["Name", "Manager"];

    let enrolled = false;
    try {
      console.log(user.student.data().detail.enrolled, "vs", id);
      if (user.student.data().detail.enrolled.includes(id))
        enrolled = true
    } catch (err) { }

    let imgtag = '';
    if (typeof img === 'string' && img.startsWith("http")) imgtag = `<img src="${img}" />`;
    else if (img) imgtag = img;

    let className = "";
    if (name.endsWith("*")) className += " bg-secondary text-light"
    if (finished.includes(name)) className += " border-success border"
    if (enrolled) className += " enrolled"

    return [
      {
        v: name,
        f: `
          <div class="card py-2 mx-1 ${className} pointer" style="height: 100%">
              <div class="card-img-top">${imgtag}</div>
              <div class="card-body">${typeof name === "string" && name.replace("*", "")}</div>
          </div>
            `,
      },
      par,
    ];
  });

  return (
    <Chart
      width={"100%"}
      chartType="OrgChart"
      loader={<div>Loading Chart</div>}
      data={chartData}
      options={{
        allowHtml: true,
      }}
      rootProps={{ "data-testid": "1" }}
      nodeClass="OrgChartNode"
      selectedNodeClass="bg-dark"
      className="mt-3"
      chartEvents={[
        {
          eventName: "ready",
          callback: ({ chartWrapper, google }) => {
            const chart = chartWrapper.getChart();
            google.visualization.events.addListener(chart, "select", e => {
              let selection = chart.getSelection();
              if (selection && selection[0])
                handleSelect(selection[0].row)
            });
          }
        }
      ]}
    />
  );
}
