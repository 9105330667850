import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  FormControl,
  FormGroup,
  Modal,
  Table
} from "react-bootstrap";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import ContainerWithHeader from "../../../components/ContainerWithHeader";
import { getCourseByCid } from "../../../pages/frontpage/course-data";
import { fstore } from "../../../utils/fb";
import SiteContext from "../../../utils/site-context";
import NotesrichEditorLite from "../notes-rich-editor-lite";

// import NotesrichEditorLite from "../notes-rich-editor-lite";

const CHAPTERS = fstore.collection("course-chapters");

export default function CourseIndex({ match }) {

  const { cid } = match.params;
  const menuCourse = getCourseByCid(cid.split("-").pop());

  const [menuChapters, setMenuChapters] = useState(undefined);
  const { setShowLoginModal, user } = useContext(SiteContext);
  const [showLoginAlert, setShowLA] = useState(!!user.isGuest);
  const [editChapter, setEditChapter] = useState(undefined);
  const { adminMode } = useContext(SiteContext);

  const title = menuCourse && (
    <Link to={`/learn/course/${menuCourse.name}-${menuCourse.id}`}>
      <img src={menuCourse.img} style={{ height: 30 }} className="mr-2" alt="" />
      {menuCourse.name}
    </Link>
  );

  useEffect(() => {
    CHAPTERS.where("course", "==", cid.split("-").pop()).get().then(s => s.docs).then(chapters => {
      chapters.sort((a, b) => a.data().index - b.data().index);
      setMenuChapters(chapters);
    });
  }, [cid])

  return (
    <ContainerWithHeader title={title} contentClassName="mt-5">
      <Alert
        variant="info"
        show={showLoginAlert}
        onClose={() => setShowLA(false)}
        dismissible
      >
        {"💡 "}
        <button className="btn-a" onClick={() => setShowLoginModal(true)}>
          Login
        </button>{" "}
        to view and track your progress
      </Alert>

      <Switch>
        <Route path="/learn/course/:cid/:chid/notes" component={NotesrichEditorLite} />
        {/* <Route path="/learn/course/:cid/:chid/notes" component={NotesrichEditor} /> */}
        {/* <Route path="/learn/course/:cid/:chid/notes" component={NotesNotion} /> */}
        <Route path="/">
          <ChapterList adminMode={adminMode} chapters={menuChapters} setChapters={setMenuChapters}
            editChapter={editChapter} setEditChapter={setEditChapter} course={menuCourse}
          /></Route>
      </Switch>

    </ContainerWithHeader>
  );
}

function ChapterList({
  adminMode, chapters, setChapters, editChapter, course,
  setEditChapter
}) {

  const [, setExAr] = useState([]);
  const [exerciseEditorEntry, setExerciseEditorEntry] = useState(undefined);

  async function addExercise(formData) {
    const data = {
      courseid: course.id,
      chapterid: exerciseEditorEntry.chid,
      detail: formData,
    };
    let { id } = await fstore.collection("course-chapter-exercises").add(data);
    setExAr(v => [...v, { id, data: () => data }]);
  }

  return (
    <>
      <Table>
        <thead>
          <tr className="bg-dark text-light">
            <th colSpan={2}>Name</th>
            {adminMode && <th>Admin</th>}
          </tr>
        </thead>
        <tbody>
          {chapters && chapters.map((ch) => (
            <Chapter
              key={ch.id}
              ch={ch}
              adminMode={adminMode}
              setChapters={setChapters}
              onEdit={() => setEditChapter(ch)}
              setExerciseEditorEntry={() => setExerciseEditorEntry({ chid: ch.id })}
              addExercise={addExercise}
            />
          ))}
        </tbody>
      </Table>

      {adminMode && (
        <div className="my-2">
          <Button onClick={() => setEditChapter({})}>+ Chapter</Button>
        </div>
      )}

      <ChapterEditModal
        show={editChapter !== undefined}
        onHide={() => setEditChapter(undefined)}
        chapters={chapters}
        setChapters={setChapters}
        ch={editChapter}
        key={editChapter && editChapter.id}
        course={course}
      />

      <ExerciseEditor
        hide={() => setExerciseEditorEntry(undefined)}
        exerciseEditorEntry={exerciseEditorEntry}
        addExercise={addExercise}
      />
    </>
  )
}

function Chapter({ ch, adminMode, onEdit, setChapters, setExerciseEditorEntry, addExercise }) {
  const { pathname } = useLocation();
  let { name, index } = ch.data();

  async function deleteChapter() {
    if (!window.confirm("are you sure? If there is a sub-collection, manually delete them first")) return;
    CHAPTERS.doc(ch.id).delete();

    setChapters((chapters) => {
      let ret = chapters.filter((c) => c.id !== ch.id);
      ret.forEach((v, i) => {
        if (v.data().index !== i) {
          CHAPTERS.doc(v.id).update({ index: i });
          ret[i] = { id: v.id, data: () => ({ ...v.data(), index: i }) };
        }
      });
      return ret;
    });
  }

  return (
    <tr>
      <td>
        {index}. {name}
      </td>
      <td>
        <Link to={`${pathname}/${name.toLowerCase().replace(/\s/g, "-")}-${ch.id}/notes`}>
          <Button variant="outline-success" size="sm">{'📖'} Notes</Button>
        </Link>
        <Button className="ml-1" variant="outline-info" size="sm">{'✍️'} exercise</Button>
        {
          adminMode && <Button size="sm" className="ml-1" onClick={() => setExerciseEditorEntry({})}>+</Button>
        }
      </td>
      {adminMode && (
        <td>
          <Button variant="dark" size="sm" className="ml-1" onClick={onEdit}>Edit</Button>
          <Button variant="danger" size="sm" className="ml-1" onClick={deleteChapter}>Del</Button>
        </td>
      )}
    </tr>
  );
}

function ChapterEditModal({ show, onHide, ch, course, chapters, setChapters }) {
  let formRef = useRef(undefined);

  if (!ch) return <></>;
  let { name, index, notionId } = ch.data ? ch.data() : {};

  function resetForm() {
    formRef.current.reset();
    // console.log(formRef.current);
  }

  async function onSubmit(ev) {
    if (ev)
      ev.preventDefault();
    const formData = Object.fromEntries(new FormData(formRef.current));
    let doc;
    if (ch.id) {
      // update
      await CHAPTERS.doc(ch.id).update(formData);
      doc = await CHAPTERS.doc(ch.id).get();
    } else {
      let { id } = await CHAPTERS.add({ ...formData, course: course.id });
      doc = await CHAPTERS.doc(id).get();
    }

    // rearrage
    let ret = chapters.filter(v => v.id !== doc.id);
    ret.splice(doc.data().index, 0, doc);
    ret.forEach((v, i) => {
      if (i !== index) {
        CHAPTERS.doc(v.id).update({ index: i });
        ret[i] = { id: v.id, data: () => ({ ...v.data(), index: i }) };
      }
    });

    setChapters(ret);
    onHide()
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        {index} {name}
      </Modal.Header>
      <Modal.Body>
        <form ref={formRef} onSubmit={onSubmit}>
          <FormGroup>
            <label>Index</label>
            <FormControl name="index" defaultValue={index}></FormControl>
          </FormGroup>
          <FormGroup>
            <label>Name</label>
            <FormControl name="name" defaultValue={name}></FormControl>
          </FormGroup>
          <FormGroup>
            <label>NotionId</label>
            <FormControl name="notionId" defaultValue={notionId}></FormControl>
          </FormGroup>
          <button type="submit" style={{ display: "none" }} />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <input type="reset" className="btn btn-secondary" onClick={resetForm} />
        <Button className="ml-1" onClick={onSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ExerciseEditor({ exerciseEditorEntry: entry, hide, addExercise }) {

  function onSubmit(e) {
    e.preventDefault();
    const formData = Object.fromEntries(new FormData(e.target));
    addExercise(formData).then(hide);
  }

  return (<Modal show={entry !== undefined} onHide={hide}>
    <Modal.Header closeButton>
      {entry?.id ? "Edit" : "Add"}
    </Modal.Header>
    <Modal.Body>
      <form onSubmit={onSubmit}>
        <FormGroup>
          <label>Name</label>
          <FormControl name="name" defaultValue={entry?.name}></FormControl>
        </FormGroup>
        <FormGroup>
          <label>Link</label>
          <FormControl name="link" defaultValue={entry?.link}></FormControl>
        </FormGroup>
        <Button type="submit">{entry?.id ? "Edit" : "Add"}</Button>
      </form>
    </Modal.Body>
  </Modal>)
}