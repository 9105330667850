import toObject from "form-to-object";
import React, { Fragment, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Toast,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
const formExclude = ["created_at", "updated_at"];

function CrudForm({ show, hide, doc, onSave, onDelete, model, children }) {
  const [isLoading, setLoading] = useState(false);
  const formRef = useRef(undefined);

  async function onSubmit(ev) {
    ev.preventDefault();
    if (isLoading) return Promise.resolve();
    const formData = toObject(formRef.current);

    // convert for types
    model.forEach(({ name, type }) => {
      if (!formData[name]) return;
      if (type === "boolean") formData[name] = !!formData[name];
      if (type === "array" && formData[name] instanceof Array === false)
        formData[name] = [formData[name]];
      if (type === "number") formData[name] = parseInt(formData[name], 10);
    });
    setLoading(true);
    await onSave(formData);
    hide();
  }

  async function onDeleteClick() {
    if (!window.confirm("Are you sure?!")) return;
    if (isLoading) return Promise.resolve();

    setLoading(true);
    await onDelete();
    hide();
  }

  

  if (!doc) return <Fragment />;
  const data = doc["id"] ? doc.data() : {};

  return (
    <Modal show={show} onHide={hide} centered size="xl">
      <Modal.Header closeButton>{!doc["id"] ? "ADD" : "EDIT"}</Modal.Header>
      <Form onSubmit={onSubmit} ref={formRef}>
        <Modal.Body>
          {model
            .filter((col) => !formExclude.includes(col.name))
            .map((col) => {
              return (
                <Form.Group key={col.name} as={Row}>
                  <Form.Label column sm="3">
                    {col.name}
                  </Form.Label>
                  <Col sm={9}>
                    <FormController col={col} data={data[col.name]} />
                  </Col>
                </Form.Group>
              );
            })}
            {children}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isLoading}
            type="button"
            variant="danger"
            onClick={onDeleteClick}
          >
            {isLoading ? <i className="fas fa-spin fa-spinner" /> : "Delete"}
          </Button>

          <Button disabled={isLoading} type="submit">
            {isLoading ? <i className="fas fa-spin fa-spinner" /> : "Submit"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function RadioInput({ col, data }) {
  return (
    <div style={{ height: "auto" }}>
      <ToggleButtonGroup name={col.name} type={col.type} defaultValue={data}>
        {col.options.map((op, i) => (
          <ToggleButton
            key={i}
            value={op}
            variant="outline-dark"
            className={"btn-sm"}
          >
            {op}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
}

function ArrayInput({ col, data }) {
  const [ar, setAr] = useState(data instanceof Array ? data : [data]);

  function onKeyUp(ev) {
    if (ev.key === "Enter") {
      let names = ev.target.value.split(",").map((v) => v.trim());
      setAr((ar) => [...ar, ...names]);
      ev.target.value = "";
    }
  }

  function onDelete(index) {
    setAr((ar) => ar.filter((_, i) => i !== index));
  }

  return (
    <div>
      {ar &&
        ar.map((v, i) => (
          <Toast key={i} onClose={() => onDelete(i)}>
            <Toast.Header>
              <div
                className="rounded mr-2"
                alt=""
                style={{
                  width: "20px",
                  height: "20px",
                  background: "var(--gray)",
                }}
              />
              <strong className="mr-auto">{v}</strong>
              <input
                type="checkbox"
                name={col.name}
                defaultChecked={true}
                value={v}
                style={{ visibility: "hidden" }}
              />
            </Toast.Header>
          </Toast>
        ))}
      <input
        onKeyUp={onKeyUp}
        type="text"
        className="form-control"
        placeholder="Use comma(,) to add multiple names"
      />
    </div>
  );
}

function FormController({ col, data }) {
  switch (col.type) {
    case "array":
      return <ArrayInput col={col} data={data || []} />;
    case "boolean":
      return (
        <div>
          <Form.Check
            type="switch"
            name={col.name}
            defaultChecked={!!data}
            id={col.name}
            label=" "
          />
        </div>
      );
    case "radio":
    case "checkbox":
      return <RadioInput col={col} data={data} />;
    default:
      // text, number, date
      return (
        <Form.Control name={col.name} type={col.type} defaultValue={data} />
      );
  }
}
export default CrudForm;
