import { format, parseISO } from 'date-fns';
import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';

function InputboxDate({ id, value, updateValue, dformat = 'yyyy-MM-dd', enabled = false, ...props }) {
  const ref = useRef(undefined);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const invokeChange = async () => {
    setLoading(true);
    await updateValue(parseISO(ref.current.value));
    setLoading(false);
    setEditMode(false);
  }

  if (!editMode || !enabled) {
    return (
      <div>
        <span className={`p-2 ${enabled ? 'highlight-onhover' : ''}`} onClick={() => setEditMode(true)}>
          {format(value.toDate(), 'yyyy-MM-dd EEE')}
        </span>
      </div>
    )
  }

  return (
    <div className="d-flex flex-column justify-content-center">
      <input className="form-control mr-2" id={"dateInput" + id} type="date" {...props} defaultValue={format(value.toDate(), "yyyy-MM-dd")} ref={ref} />
      <div className="mt-2">
        <Button size="sm" variant="primary" onClick={invokeChange} disabled={loading}>{loading ? "Saving..." : "Save"}</Button>
        <Button size="sm" variant="secondary" onClick={() => setEditMode(false)} disabled={loading} className="ml-1">Cancel</Button>
      </div>
    </div>
  )
}

export { InputboxDate };

