import React, { useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FaCheck, FaSpinner, FaTimes } from 'react-icons/fa';
import TextareaAutosize from 'react-textarea-autosize';

function TextBoxInput({ value, updateValue, type = "text", ...props }) {
  const ref = useRef(undefined);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    await updateValue(ref.current.value);
    setEditing(false);
    setLoading(false);
  }

  return (
    <Form onReset={() => setEditing(false)} onSubmit={onSubmit}>
      <TextareaAutosize className="form-control" {...props} as={type} defaultValue={value} ref={ref} onChange={() => setEditing(true)} rows={10} cols={50} />
      {
        editing && (
          <>
            <Button size='sm' type='submit'>{loading ? <FaSpinner /> : <FaCheck />}</Button>
            <Button size='sm' type="reset"><FaTimes /></Button>
          </>
        )
      }
    </Form>
  )
}

export default TextBoxInput