import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import ContainerWithHeader from '../components/ContainerWithHeader';
import Loading from '../components/loading';
import Markdown from '../learn/course/markdown';

export default function Handbook() {

  let [md, setMd] = useState(undefined);

  useEffect(() => {
    Axios.get("https://raw.githubusercontent.com/BTREE-ACADEMY/static-api/main/md/handbook-v1.md")
      .then(resp => setMd(resp.data));

  }, [])

  return (
    <ContainerWithHeader title="Parent Handbook">
      {
        typeof md === 'undefined' && <Loading msg="Loading handbook data" />
      }
      {
        typeof md !== 'undefined' && <div className="NotesRichEditorLite">
          <Markdown source={md} />
        </div>
      }
    </ContainerWithHeader>
  )
}