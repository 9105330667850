import React, { useState, useEffect } from "react";
import ContainerWithHeader from "../../components/ContainerWithHeader";
import { fstore } from "../../utils/fb";
import { Button } from "react-bootstrap";

export default function QuizList({ match }) {
  let [, setQuizAr] = useState([]);
  let [, setShowEditor] = useState(false);

  useEffect(() => {
    fstore
      .collection("quizzes")
      .where("tag", "==", match.params.tag)
      .get()
      .then((s) => s.docs)
      .then(setQuizAr);
  }, [match.params.tag]);

  return (
    <ContainerWithHeader
      title={`Quiz - ${match.params.tag}`}
    >
      <div className="d-flex justify-content-end">
        <Button onClick={() => setShowEditor(true)}>+</Button>
      </div>

      {/* <QuizEditor showEditor={showEditor} hideEditor={() => setShowEditor(false)} /> */}
    </ContainerWithHeader>
  );
}
