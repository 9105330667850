import { format } from 'date-fns';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Breadcrumb, Button } from 'react-bootstrap';
import { FaUserAlt } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import MDEditorLite from "react-markdown-editor-lite";
import 'react-markdown-editor-lite/lib/index.css';
import { LinkContainer } from 'react-router-bootstrap';
import ContainerWithHeader from '../../components/ContainerWithHeader';
import Loading from '../../components/loading';
import CodeBlock from '../../components/syntax-highliter/codeblock';
import { firebase, fstore, fupload } from '../../utils/fb';
import SiteContext from '../../utils/site-context';
import QnaModal from './components/qna-modal';

export default function QnaEntry({ match }) {
  const { qid } = match.params;
  const { user } = useContext(SiteContext);
  const [q, setQ] = useState(undefined);
  const [text, setText] = useState(undefined);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    fstore.collection("questions").doc(qid).get().then(setQ);
    fstore.collection("questions-text").doc(qid).get().then(setText);
  }, [qid])

  function onModalFormSubmit(formData) {
    const { topic, title, isPrivate, body } = formData;

    let p1 = fstore.collection("questions").doc(qid).update({ detail: { title, topic, isPrivate: !!isPrivate, displayName: user.displayName } }).then(() => {
      fstore.collection("questions").doc(qid).get().then(setQ);
    })
    let p2 = fstore.collection("questions-text").doc(qid).update({ text: body }).then(() => {
      fstore.collection("questions-text").doc(qid).get().then(setText);
    })
    Promise.all([p1, p2]).then(() => setOpenModal(false));
  }

  if (!q || !text) return <Loading />

  const { detail, uid, /*solved, postedDate*/ } = q.data();
  const { title, topic, isPrivate, displayName } = detail;

  return <ContainerWithHeader title="QnA">

    <Breadcrumb>
      <LinkContainer to={`/learn/qna`}>
        <Breadcrumb.Item>QnA Home</Breadcrumb.Item>
      </LinkContainer>
    </Breadcrumb>

    <h1>{title}</h1>
    <div className="my-3 text-right">Asked by <FaUserAlt /> {isPrivate ? "Anonymous" : displayName}</div>
    {
      user.uid === uid && <div className="my-3 text-right"><Button size="sm" onClick={() => setOpenModal(true)}>Edit</Button></div>
    }

    <p>
      <ReactMarkdown source={text.data().text} renderers={{ code: CodeBlock }} />
    </p>

    <Answers qid={qid} />

    <QnaModal show={openModal} onHide={() => setOpenModal(false)} onModalFormSubmit={onModalFormSubmit} defaultValues={{ title, topic, isPrivate, text: text.data().text }} />
  </ContainerWithHeader>
}

function Answers({ qid }) {
  const [ar, setAr] = useState([]);
  const [editorText, setEditorText] = useState('');
  const { user } = useContext(SiteContext);

  useEffect(() => {
    fstore.collection("questions").doc(qid).collection("answers").orderBy("timestamp", "asc").get().then(s => setAr(s.docs));
  }, [qid]);

  async function handleImageUpload(file) {
    let ref = fupload.ref(`qna/${format(new Date(), "yyyy-mm")}/${new Date().getDate()}`);
    await ref.put(file);
    return await ref.getDownloadURL();
  }

  async function submitAnswer() {
    fstore.collection("questions").doc(qid).update({
      solved: user.isAdmin,
      numAnswers: firebase.firestore.FieldValue.increment(1),
    });

    let { id } = await fstore.collection("questions").doc(qid).collection("answers").add({
      uid: user.uid, timestamp: new Date(), detail: {
        displayName: user.displayName,
        text: editorText
      }
    });
    let doc = await fstore.collection("questions").doc(qid).collection("answers").doc(id).get();

    setAr(p => [...p, doc]);
    setEditorText('');
  }

  // async function deleteAnswer(aid) {
  //   await fstore.collection("questions").doc(qid).collection("answers").doc(aid).delete();
  //   setAr(p => p.filter(v => v.id !== aid));
  // }

  return (
    <div className="answers">
      <h4 className="mt-5 mb-2">{ar.length} answer{ar.length > 1 ? "s" : ""}</h4>

      <div style={{
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridRowGap: '20px'
      }}>
        {
          ar.map(v => <Fragment key={v.id}>
            <div className="font-weight-bold"> <FaUserAlt /> {v.data().detail.displayName}</div>
            <div className="ml-3 rounded p-3" style={{ background: '#eee' }}><ReactMarkdown source={v.data().detail.text} renderers={{ code: CodeBlock }} /></div>
          </Fragment>)
        }
      </div>

      <hr className="my-5" />

      <h3 className="d-flex justify-content-between">
        <div>Post a comment / answer</div>
        <Button onClick={submitAnswer}>Submit</Button>
      </h3>

      <MDEditorLite
        value={editorText}
        style={{ height: "500px", width: "100%" }}
        renderHTML={(text) => <ReactMarkdown source={text} />}
        onChange={({ html, text }) => setEditorText(text)}
        onImageUpload={handleImageUpload}
        view={{menu: true, md: true, html: false}}
      />

    </div>
  )
}