import { format, parse } from "date-fns";
import { addMonths } from "date-fns/esm";
import formToObject from "form-to-object";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Nav,
  Row,
  Table
} from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import ContainerWithHeader from "../../components/ContainerWithHeader";
import { ADMINS } from "../../utils/constants";
import { fstore } from "../../utils/fb";
import SiteContext from "../../utils/site-context";

const officeWorkPlaceholer = `Office work references
- github commit url
- quiz-bank question names
- notion.so url
`;

const TIMECARDS = fstore.collection("timecards");

const minToHHMM = (min) => {
  return Math.floor(min / 60) + ":" + (min % 60 < 10 ? "0" : "") + (min % 60);
}

export default function Timecard() {
  const { user } = useContext(SiteContext);
  const [monthOffset, setMO] = useState(0); // month offset
  const [timecards, setTimecards] = useState([]);

  useEffect(() => {
    let ym = format(addMonths(new Date(), monthOffset), "yyyy-MM");
    let query = TIMECARDS.where("date", ">=", ym + "-00")
      .where("date", "<=", ym + "-32")
      .orderBy("date", "desc");
    if (!user.isSuperAdmin) query = query.where("uid", "==", user.uid);
    query
      .get()
      .then((s) => s.docs)
      .then((timecards) =>
        timecards.sort((b, a) =>
          a.data().detail.name === b.data().detail.name
            ? a.data().created_at.seconds - b.data().created_at.seconds
            : a.data().detail.name.localeCompare(b.data().detail.name)
        )
      )
      .then(setTimecards);
  }, [monthOffset, user]);

  return (
    <ContainerWithHeader title="Timecard 🕑" contentClassName="mb-5">
      <h3>Form</h3>
      <p>You can submit multiple time cards for the same day.</p>
      <InputForm setTimecards={setTimecards} />

      <h3 className="mt-4">List</h3>
      <div className="d-flex align-items-center mt-3">
        <Nav
          variant="tabs"
          activeKey={monthOffset}
          style={{ width: "100%", border: 0 }}
          onSelect={(k) => setMO(parseInt(k, 10))}
        >
          {[-2, -1, 0].map((off) => {
            let ym2 = format(addMonths(new Date(), off), "yyyy-MM");
            return (
              <Nav.Item key={off}>
                <Nav.Link eventKey={off}>{ym2}</Nav.Link>
              </Nav.Item>
            );
          })}
        </Nav>
      </div>

      <List timecards={timecards} setTimecards={setTimecards} />
    </ContainerWithHeader>
  );
}

function List({ timecards, setTimecards }) {
  const { user } = useContext(SiteContext);
  async function revoke(tid) {
    if (!window.confirm("😵 Are you sure to revoke this submission?")) return;
    await fstore.collection("timecards").doc(tid).delete();
    setTimecards((cards) => cards.filter((c) => c.id !== tid));
  }

  let sum = 0;
  return (
    <Table bordered hover>
      <thead>
        <tr>
          <td colSpan={2}>date</td>
          {user.isSuperAdmin && <td>name</td>}
          <td>class minutes</td>
          <td>office minutes</td>
          <td>acc. minutes</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        {timecards.map((t, i) => {
          let newPerson =
            i === 0 || t.data().detail.name !== timecards[i - 1].data().detail.name;
          if (newPerson) sum = 0;

          if (typeof t.data().detail.class_minutes === "number")
            sum += t.data().detail.class_minutes;
          if (typeof t.data().detail.office_minutes === "number")
            sum += t.data().detail.office_minutes;

          let dateObj = parse(t.data().date, "yyyy-MM-dd", new Date());
          return (
            <Fragment key={i}>
              {i > 0 && newPerson && (
                <tr>
                  <td colSpan={7} className="p-2 bg-light"></td>
                </tr>
              )}
              <tr key={t.id}>
                <td> {format(dateObj, "yyyy-MM-dd")}</td>
                <td> {format(dateObj, "E")}</td>
                {user.isSuperAdmin && <td>{t.data().detail.name}</td>}
                <td>{t.data().detail.class_minutes ? minToHHMM(t.data().detail.class_minutes) : '-'}</td>
                <td>
                  {t.data().detail.office_minutes ? minToHHMM(t.data().detail.office_minutes) : '-'}
                  <pre>{t.data().detail.office_minutes > 0 && t.data().detail.office_work}</pre>
                </td>
                <td>{minToHHMM(sum)}</td>
                <td>
                  <FaTimes onClick={() => revoke(t.id)} />
                </td>
              </tr>
            </Fragment>
          );
        })}
      </tbody>
    </Table>
  );
}

//region inputForm
function InputForm({ setTimecards }) {
  const { user } = useContext(SiteContext);

  async function onSubmit(ev) {
    ev.preventDefault();
    ev.persist();
    const formData = formToObject(ev.target);
    Object.keys(formData).forEach((key) => {
      if (formData[key] && !isNaN(formData[key]))
        formData[key] = parseInt(formData[key], 10);
    });

    let { date, uid_override, ...rest } = formData;

    const data = {
      created_at: new Date(),
      date,
      uid: uid_override || user.uid,
      detail: {
        ...rest,
        name: uid_override ? ADMINS.find(v => v.uid === uid_override).name : user.displayName
      }
    }

    const docRef = await fstore.collection("timecards").add(data);
    const doc = await fstore.collection("timecards").doc(docRef.id).get();
    // await idCache.addItem("timecards", serializeDoc(doc));
    setTimecards((ar) => [doc, ...ar]);
    ev.target.reset();
  }

  return (
    <Card>
      <Card.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group as={Row} controlId="date">
            <Form.Label column sm={2}>
              Date
            </Form.Label>
            <Col sm="10">
              <Form.Control
                name="date"
                type="date"
                defaultValue={format(new Date(), "yyyy-MM-dd")}
              />
            </Col>
          </Form.Group>
          <div>
            <Alert variant="info">
              You can now input your times in minutes.
            </Alert>
          </div>
          {user.isSuperAdmin && (
            <Form.Group as={Row} controlId="class_minutes">
              <Form.Label column sm={2}>Employee</Form.Label>
              <Col sm="10">
                <Form.Control as="select" name="uid_override">
                  {
                    ADMINS.map(ad => <option key={ad.uid} value={ad.uid}>{ad.name}</option>)
                  }
                </Form.Control>
              </Col>
            </Form.Group>
          )}
          {user.isSuperAdmin && (
            <Form.Group as={Row} controlId="class_minutes">
              <Form.Label column sm={2}>Class minutes</Form.Label>
              <Col sm="10">
                <Form.Control
                  name="class_minutes"
                  type="number"
                  defaultValue={0}
                />
              </Col>
            </Form.Group>
          )}
          <Form.Group as={Row} controlId="office_minutes">
            <Form.Label column sm={2}>
              Office minutes
            </Form.Label>
            <Col sm="10">
              <Form.Control
                name="office_minutes"
                type="number"
                defaultValue={0}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="office_work">
            <Form.Label column sm={2}>
              Office-work
            </Form.Label>
            <Col sm="10">
              <Form.Control
                name="office_work"
                as="textarea"
                rows={5}
                placeholder={officeWorkPlaceholer}
              />
            </Col>
          </Form.Group>
          <Button type="submit">Submit</Button>
        </Form>
      </Card.Body>
    </Card>
  );
}
//endregion
