import { format } from 'date-fns';
import React, { useState } from 'react';
import { Button, Form, Modal, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
// import MDEditor from 'rich-markdown-editor';
// import { mEditorDkTheme } from '../../course/notes/note-theme';
// import "../../../rich-markdown-editor.scss";
import MDEditorLite from "react-markdown-editor-lite";
import 'react-markdown-editor-lite/lib/index.css';
import CodeBlock from '../../../components/syntax-highliter/codeblock';
import { fupload } from '../../../utils/fb';
import "./qna-modal.scss";


const defaultBody = `What is wrong with the following java code?

\`\`\`java
public static void main(String args[]) {
  System.out.println("hello");
}
\`\`\``

export default function QnaModal({ show, onHide, onModalFormSubmit, defaultValues }) {
  // const getContent = useRef(undefined);
  const [saving, setSaving] = useState(false);
  const [editorText, setEditorText] = useState(defaultValues ? defaultValues.text : defaultBody);

  async function handleImageUpload(file) {
    let ref = fupload.ref(`qna/${format(new Date(), "yyyy-mm")}/${new Date().getDate()}`);
    await ref.put(file);
    return await ref.getDownloadURL();
  }

  function onSubmit(e) {
    e.preventDefault();
    setSaving(true);

    const formData = Object.fromEntries(new FormData(e.target));
    // onModalFormSubmit(formData, getContent.current());
    onModalFormSubmit(formData);
    setSaving(false);
  }

  return (
      <Modal show={show} onHide={onHide} size="lg" dialogClassName="modal-xxl">
        <Modal.Header closeButton>          Ask Question    </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control name="title" placeholder="e.g. How do you print Hello World?" defaultValue={defaultValues?.title} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Topic</Form.Label><br />
              <ToggleButtonGroup name="topic" type="radio" defaultValue={defaultValues?.topic}>
                <ToggleButton variant="outline-primary" value="java">Java</ToggleButton>
                <ToggleButton variant="outline-primary" value="python">Python</ToggleButton>
                <ToggleButton variant="outline-primary" value="html">HTML/CSS</ToggleButton>
                <ToggleButton variant="outline-primary" value="javascript">Javascript</ToggleButton>
                <ToggleButton variant="outline-primary" value="competition">Competition</ToggleButton>
                <ToggleButton variant="outline-primary" value="unity">Unity</ToggleButton>
                <ToggleButton variant="outline-primary" value="pygame">PyGame</ToggleButton>
              </ToggleButtonGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>Body</Form.Label>
              {/* <div className="RichMarkdownEditor p-3 border rounded">
            <MDEditor defaultValue={defaultValues ? defaultValues.text : defaultBody} autoFocus
              onChange={gc => getContent.current = gc}
              uploadImage={handleImageUpload}
              theme={mEditorDkTheme}
              value={defaultValues ? defaultValues.text : defaultBody}
            />
          </div> */}
              <MDEditorLite
                value={editorText}
                style={{ height: "500px" }}
                renderHTML={(text) => React.createElement(ReactMarkdown, { source: text, code: CodeBlock })}
                onChange={({ html, text }) => setEditorText(text)}
                onImageUpload={handleImageUpload}
                name="body"
              />

              <div className="muted text-right my-3" style={{ fontSize: '0.8em' }}>
                ```<code>code</code>```<span className="ml-3" />
            **<strong>bold</strong>**<span className="ml-3" />
            *<em>italics</em>*<span className="ml-3" />
            drag image to upload
          </div>
            </Form.Group>
            <Form.Group controlId="qnaModelisPrivate">
              <Form.Check name="isPrivate" type="checkbox" label="Hide my name" defaultValue={defaultValues?.isPrivate} />
            </Form.Group>
            <Button type="submit" disabled={saving}>
              {saving ? "Submitting...." : 'Submit'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
  )
}