import { format, subDays } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { Badge, Button, Modal, Table } from 'react-bootstrap';
import { FaCode } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import ContainerWithHeader from '../../components/ContainerWithHeader';
import CodeBlock from '../../components/syntax-highliter/codeblock';
import { fstore } from '../../utils/fb';
import SiteContext from '../../utils/site-context';
import AlgoForm from './algo-form';


function getSourceVariant(src) {
  switch (src) {
    case "SPOJ": return "info";
    case "Hackerrank": return "success";
    case "Code Forces": return "danger";
    default: return "primary";
  }
}

function getGradeVariant(completedness) {
  switch (completedness) {
    case "All Credit": return "success";
    case "incomplete": return "dark";
    default: return "danger";
  }
}

function AlgoIndex() {
  const { user } = useContext(SiteContext);

  return (
    <ContainerWithHeader title="🐱‍🐉 Algo">
      <h3>{'⏲️'}Recent Submissions</h3>
      {
        user.student && <AlgoTable student={user.isAdmin ? undefined : user.student} />
      }
    </ContainerWithHeader>
  )
}

function AlgoTable({ student }) {

  const [showForm, setShowForm] = useState(false);
  const [ar, setAr] = useState([])
  const [submission, setSubmission] = useState(undefined);
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const { user } = useContext(SiteContext);

  useEffect(() => {
    if (student && student.data().login_id2) {
      fstore.collection("algo-submissions")
        .where('login_id', '==', student.data().login_id2)
        .where('createdAt', ">", subDays(new Date(), 14))
        .orderBy("createdAt", "desc")
        .get()
        .then(ss => ss.docs)
        .then(setAr);
    }
  }, [student, user.isAdmin])

  async function onCreate(formData) {
    let { analysis, code, ...rest } = formData;
    rest.createdAt = new Date();
    rest.login_id = user.student.data().login_id2;
    let { id } = await fstore.collection("algo-submissions").add(rest);
    let doc = await fstore.collection('algo-submissions').doc(id).get();
    await fstore.collection('algo-submission-code').doc(id).set({ detail: { analysis, code } })
    setAr([doc, ...ar]);
    return Promise.resolve();
  }

  async function loadSubmission(id) {
    setSubmissionLoading(true);
    let doc = await fstore.collection('algo-submission-code').doc(id).get();
    setSubmission(doc);
    setSubmissionLoading(false);
  }

  return <>
    <Table striped bordered >
      <thead>
        <tr>
          <th>Problem</th>
          <th>Submission Date</th>
          <th>Code and Analysis</th>
          <th>Grade</th>
          {user.isAdmin && <th>login_id</th>}
        </tr>
      </thead>
      <tbody>
        {
          ar.map(v => (
            <tr key={v.id}>
              <td>
                <Badge variant={getSourceVariant(v.data().source)} className="mr-2">{v.data().source}</Badge>
                {v.data().problem}
              </td>
              <td>
                {format(v.data().createdAt.toDate(), "yyyy-MM-dd hh:mm a")}
              </td>
              <td><Button variant="secondary" size="sm" onClick={() => loadSubmission(v.id)}><FaCode /> View</Button></td>
              <td>
                <Badge variant={getGradeVariant(v.data().completedness)} className="mr-2">{v.data().completedness}</Badge>
              </td>
              {user.isAdmin && <td>{v.data().login_id}</td>}
            </tr>
          ))
        }
      </tbody>
    </Table>

    {
      user.isStudent && (<div className="text-right">
        <Button variant="primary" onClick={() => setShowForm(true)}>+ Submit</Button>
      </div>)
    }


    <AlgoForm show={showForm} onHide={() => setShowForm(false)} onCreate={onCreate} />

    {/* modal entry */}
    <Modal size="lg" show={submission || submissionLoading} onHide={() => setSubmission(undefined)} >
      <Modal.Header>{submissionLoading ? "Loading" : "Code and Analysis"}</Modal.Header>
      <Modal.Body>
        <h4>Analysis</h4>
        <ReactMarkdown source={submission?.data().detail.analysis} />
        <hr />
        <h4>Code</h4>
        {submission && <CodeBlock language="java" value={submission?.data().detail.code} />}
        {
          submission && user.isAdmin && (
            <textarea>{submission?.data().detail.code}</textarea>
          )
        }
      </Modal.Body>
    </Modal>
  </ >
}

export { AlgoIndex, AlgoTable };
