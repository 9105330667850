import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Crud from "./admin-pages/Crud";
import DailyReport from "./admin-pages/daily-report/daily-report";
import Dashboard from "./admin-pages/dashboard/dashboard";
import AdminStudent from "./admin-pages/student/admin-student";
import Timecard from "./admin-pages/timecard/timecard";
import StudentNav from "./components/navigations/student-nav";
import TopNav from "./components/navigations/top-nav";
import PrivateRoute from "./components/private-route";
import { AlgoIndex } from "./learn/algo/algo-index";
import CourseIndex from "./learn/course/course-index/course-index";
import Courses from "./learn/courses";
import StudentDashboard from "./learn/dashboard/student-dashboard";
import Exercise from "./learn/exercise-component/exercise";
import { QnA } from "./learn/qna/qna";
import QnaEntry from "./learn/qna/qna-entry";
import QuizPortal from "./learn/quiz/quiz-index";
import QuizList from "./learn/quiz/quiz-list";
import MemberProfile from "./member/profile";
import Signin from "./pages/signin/signin";
import Handbook from "./parent/handbook";
import SiteContext from "./utils/site-context";

function Main() {
  const { user } = useContext(SiteContext);
  return (
    <div className="Main">
      <Switch>
        {user.isAdmin && (
          <Route path={"/admin"} component={AdminDomainRouter} />
        )}

        {!user.isGuest && (
          <Route path={"/"} component={MemberRouter} />
        )}
        <PublicDomainRouter />
      </Switch>
    </div>
  );
}

function PublicDomainRouter() {
  return (
    <>
      <TopNav />
      <Switch>
        {/* <Route path="/how-we-teach" component={HowWeTeach} /> */}
        <Route path="/signin" component={Signin} />
        {/* <Route path="/" component={FrontPage} /> */}
        <Route path="/">
          <Redirect to="/learn" />
        </Route>

      </Switch>
    </>
  );
}

function MemberRouter() {
  return (
    <div className="d-sm-flex">
      <StudentNav />
      <div className="flex-grow-1">
        <Switch>
          <Route path="/parent/handbook" component={Handbook} />
          <Route path="/member/profile" component={MemberProfile} />

          <Route path="/learn/course/:cid" component={CourseIndex} />
          <Route path="/learn/quiz/tag/:tag" component={QuizList} />
          <Route path="/learn/quiz" component={QuizPortal} />
          <Route path="/learn/courses" component={Courses} />
          <Route path="/learn/exercise" component={Exercise} />
          <Route path="/learn/qna/:qid" component={QnaEntry} />
          <Route path="/learn/qna" component={QnA} />
          <Route path="/learn/algo" component={AlgoIndex} />
          <Route exact path="/">
            <Redirect to="/learn" />
          </Route>
          <PrivateRoute exact path="/learn"><StudentDashboard /></PrivateRoute>
        </Switch>
      </div>
    </div>
  );
}

function AdminDomainRouter() {
  return (
    <>
      <TopNav />
      <Switch>
        {/*super admin*/}
        <Route path="/admin/crud/:model?" component={Crud} />

        {/*normal admin*/}
        <Route path="/admin/timecard" component={Timecard} />
        <Route path="/admin/schedule" component={Dashboard} />
        <Route path="/admin/daily-report/:date?" component={DailyReport} />
        <Route path="/admin/student/:uid?" component={AdminStudent} />
        <Route path="/admin/" component={Dashboard} />
      </Switch>
    </>
  );
}

export { Main };

