import styled from "styled-components";

const GridTable = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.numCols || 2}, auto);

  border-top: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  align-items: start;
  
  &.bordered > * {
    border-right: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }

  > * {
    padding: 10px;
    min-height: 1.2em;
  }

  > .header {
    font-weight: bold;
  }

  > .span-row {
    grid-column: 1 / span ${(props) => props.numCols || 2};
    background: rgb(0, 0, 0, 0.03);
  }


`;

export { GridTable };
