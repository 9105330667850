import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FaQuestion } from "react-icons/fa";
import { LinkContainer } from 'react-router-bootstrap';
import ContainerWithHeader from "../../components/ContainerWithHeader";

const TAGS = "warmup,array-1,array-2,array-3,array-4,logic-1,logic-2,logic-3".split(
  ","
);

export default function QuizPortal() {
  return (
    <ContainerWithHeader
      title={
        <>
          <FaQuestion style={{ marginRight: 5 }} />
          Quiz Portal
        </>
      }
    >
      <Row>
        {TAGS.map((t) => (
          <TagCard key={t} t={t} />
        ))}
      </Row>
    </ContainerWithHeader>
  );
}

function TagCard({ t }) {
  return (
    <LinkContainer to={"/learn/quiz/tag/" + t}>
      <Col lg={3} md={6} sm={12} xs={12} className="mb-4 pointer">
        <Card>
          <Card.Header style={{ textTransform: "capitalize" }}>{t}</Card.Header>
          <Card.Body>...</Card.Body>
        </Card>
      </Col></LinkContainer>
  );
}
