import React, { useContext } from "react";
import { Container, Form, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FaUser } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { fauth, fstore } from "../../utils/fb";
import { yearToGrade } from "../../utils/my-date-fns";
import SiteContext from "../../utils/site-context";

const publicMenu = ["how-we-teach", "learn"];
const adminMenu = ["schedule", "daily-report", "timecard"];
const superadminMenu = [];

export default function TopNav() {
  const location = useLocation();
  const { user, setShowLoginModal, adminMode, setAdminMode, } = useContext(SiteContext);

  return (
    <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <img
              src="https://i.imgur.com/IuJpm6D.png"
              height="30"
              className="d-inline-block align-top"
              alt="logo"
            />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav style={{ textTransform: "capitalize" }}>
            {(() => {
              if (location.pathname.startsWith("/admin")) {
                return <AdminNav />;
              } else {
                return <PublicNav />;
              }
            })()}
          </Nav>
          <Nav className="mr-2 ml-auto">
            {!user.uid && (
              <Nav.Link onClick={() => setShowLoginModal(true)}>Login</Nav.Link>
            )}

            {user.uid && (
              <NavDropdown id="nav-dropdown-member" title={<FaUser />}>
                <LinkContainer to="/member/profile">
                  <NavDropdown.Item>Profile</NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Divider></NavDropdown.Divider>
                {user.isSuperAdmin && (
                  <NavDropdown.Item onClick={() => setAdminMode(v => !v)}>
                    <Form.Check
                      custom
                      type="switch"
                      label="Admin Mode"
                      checked={adminMode}
                      id="admin-mode-toggler2"
                    />
                  </NavDropdown.Item>
                )}
                <NavDropdown.Item onClick={() => fauth.signOut()}>
                  Sign Out
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

function PublicNav() {
  const { user } = useContext(SiteContext);

  return (
    <>
      {publicMenu.map((m) => (
        <LinkContainer key={m} to={`/${m}`}>
          <Nav.Link>{m.replace(/-/g, " ")}</Nav.Link>
        </LinkContainer>
      ))}
      {user.isAdmin && (
        <LinkContainer to="/admin">
          <Nav.Link>Admin</Nav.Link>
        </LinkContainer>
      )}
    </>
  );
}

let adminStudentSearchedName = ''
function AdminNav() {
  const { user } = useContext(SiteContext);
  const { push } = useHistory();

  function onSelectStudent({ value }) {
    push(`/admin/student/${value}`);
  }

  function onStudentSearch(inputValue) {
    adminStudentSearchedName = inputValue;

    return new Promise((res) => {
      if (!inputValue || inputValue.length < 3) {
        res([]);
        return;
      }

      setTimeout(() => {
        // if input value hasn't changed for 0.5 second
        if (inputValue === adminStudentSearchedName) {


          let p1 = fstore.collection("students")
            .where('name', ">=", inputValue)
            .where('name', '<=', inputValue + "zzzz")
            .get()
            .then(s => s.docs)
            .then(s => s.map(ss => ({
              label: `${ss.data().name} (${yearToGrade(ss.data().detail.graduation_year)})`,
              value: ss.id
            })))

          let cap = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
          let p2 = fstore.collection("students")
            .where('name', ">=", cap)
            .where('name', '<=', cap + "zzzz")
            .get()
            .then(s => s.docs)
            .then(s => s.map(ss => ({
              label: `${ss.data().name} (${yearToGrade(ss.data().detail.graduation_year)})`,
              value: ss.id
            })))

          Promise.all([p1, p2]).then((values) => res([...values[0], ...values[1]]));
        }
      }, 500);
    });
  }

  return (
    <>
      {adminMenu.map((m) => (
        <LinkContainer key={m} to={`/admin/${m}`}>
          <Nav.Link>{m}</Nav.Link>
        </LinkContainer>
      ))}
      {user.isSuperAdmin &&
        superadminMenu.map((m) => (
          <LinkContainer key={m} to={`/admin/${m}`}>
            <Nav.Link className="text-warning">{m}</Nav.Link>
          </LinkContainer>
        ))}

      {user.isSuperAdmin && (
        <Form inline className="ml-2">
          <AsyncSelect
            placeholder="student search"
            styles={{
              input: (styles) => ({ ...styles, minWidth: "130px" }),
              dropdownIndicator: () => ({ display: "none" }),
              indicatorSeparator: () => ({ display: "none" }),
            }}
            onChange={onSelectStudent}
            loadOptions={onStudentSearch}
          />
        </Form>
      )}
    </>
  );
}
