import React, { useRef, useState } from "react";
import { Alert, Button, Form, FormControl, FormGroup, Modal } from "react-bootstrap";
import { FaGithub, FaGoogle } from "react-icons/fa";
import { fauth, github_provider, google_provider } from "../../utils/fb";
import "./login-modal.scss";

function LoginModal({ showLoginModal, setShowLoginModal }) {

  const [loginErr, setLoginErr] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [secretCnt, setSecretCnt] = useState(0);

  const formRef = useRef(undefined);

  function doLogin(type) {

    const { username, password } = Object.fromEntries(new FormData(formRef.current));
    setLoginErr(undefined);
    setLoading(true);

    fauth.signInWithEmailAndPassword(`btreecode+${type}.${username}@gmail.com`, password)
      .then(() => {
        window.localStorage.setItem("session-data", "true");
        setShowLoginModal(false);
      })
      .catch(err => {
        setLoginErr(err.message);
        setLoading(false);
      });
  }

  return (
    <Modal
      show={showLoginModal}
      onHide={() => setShowLoginModal(false)}
      centered
      size="sm"
      className="LoginModal"
    >
      <Modal.Body className="text-center rounded">
        <div className="d-flex flex-column justify-content-center align-items-center login-header">
          <img src="https://i.imgur.com/IuJpm6D.png" className="logo-img" alt="logo" onClick={() => setSecretCnt(p => p + 1)} />
            BTREE CODE ACADEMY
        </div>
        <div className="border-bottom pb-3">
          <Form onSubmit={e => e.preventDefault()} ref={formRef}>
            <h1>Login</h1>
            {loginErr && <Alert variant="danger">{loginErr}</Alert>}

            <FormGroup>
              <FormControl placeholder="username" name="username" />
            </FormGroup>
            <FormGroup>
              <FormControl placeholder="password" name="password" type="password" />
            </FormGroup>
            <div className="text-right">
              <Button onClick={() => doLogin("student")} type="button" size="sm" disabled={loading}>{loading ? "..." : "Student Login"}</Button>
              <Button onClick={() => doLogin("parent")} type="button" size="sm" disabled={loading} className="ml-3" variant="success">{loading ? "..." : "Parent Login"}</Button>
            </div>
          </Form>
        </div>
        {
          secretCnt >= 3 && (
            <div className="d-flex flex-column align-items-center mb-4">
              <div className="my-3">OR</div>
              <Button
                variant="dark"
                onClick={() => {
                  window.localStorage.setItem("session-data", "true");
                  fauth.signInWithRedirect(github_provider)
                }}
              >
                <FaGithub /> Login with Github
          </Button>
              <Button
                variant="primary"
                className="mt-2"
                onClick={() => {
                  window.localStorage.setItem("session-data", "true");
                  fauth.signInWithRedirect(google_provider);
                }}
              >
                <FaGoogle /> Login with Google
          </Button>
            </div>
          )
        }

      </Modal.Body>
    </Modal>
  );
}

export { LoginModal };

