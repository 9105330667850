import React from "react";
import { timeToDateObj } from "../../../utils/my-date-fns";

// date --> <input type="date"> but stored as string
// timestamp --> generated date() object

const groupDays = "🌞,M,Tu,W,Th,F,Sa".split(",");
const groupModel = {
  collection: "groups",
  model: [
    {
      name: "subject",
      type: "radio",
      options: "PYTHON,JAVA,GAME,WEB,USACO".split(","),
    },
    { name: "level", type: "radio", options: ["1", "2", "3", "4"] },
    { name: "time", type: "text" },
    { name: "private", type: "boolean" },
    { name: "temp", type: "boolean" },
    { name: "day", type: "radio", options: groupDays },
    { name: "duration", type: "number" },
    {
      name: "instructor",
      type: "radio",
      options: "Daniel,David,Matt".split(","),
    },
    {
      name: "price",
      type: "number",
    },
    // { name: 'students', type: 'array' }
  ],
  comparator: (a, b) => {
    let dayDiff =
      groupDays.indexOf(a.data().day) - groupDays.indexOf(b.data().day);
    if (dayDiff !== 0) return dayDiff;
    return (
      timeToDateObj(a.data().time).getTime() -
      timeToDateObj(b.data().time).getTime()
    );
  },
  tableCells: ["day", "time", "subject", "level", "instructor"],
};

const userModel = {
  collection: "users",
  model: [
    { name: "created_at", type: "timestamp" },
    { name: "email", type: "text" },
    { name: "displayName", type: "string" },
    { name: "isAdmin", type: "boolean" },
    { name: "isSuperAdmin", type: "boolean" },
  ],
  comparator: (a, b) =>
    a.data().displayName.localeCompare(b.data().displayName),
};

const studentModel = {
  collection: "students",
  model: [
    { name: "email", type: "text" },
    { name: "graduation_year", type: "text" },
    { name: "guardian_email", type: "text" },
    { name: "guardian_phone", type: "text" },
    { name: "is_active", type: "boolean" },
    { name: "name", type: "text" },
    { name: "phone", type: "text" },
    { name: "school", type: "text" },
    { name: "updated_at", type: "text" },
    { name: "username", type: "text" },
    { name: "usertype", type: "text" },
    { name: "mother_name", type: "text" },
    { name: "father_name", type: "text" },
    { name: "trial_date", type: "date" },
    { name: "start_date", type: "date" },
    { name: "quit_date", type: "date" },
    { name: "address", type: "text" },
  ],
  comparator: (a, b) => a.data().graduation_year - b.data().graduation_year,
  tableCells: ["name", "graduation_year", "is_active"],
  customRender: {
    graduation_year: (yr) => {
      let grade = 12 - (parseInt(yr, 10) - new Date().getFullYear());
      let grade2 = grade < 12 ? grade + 1 : "🎓";
      if (isNaN(grade)) return "";
      if (grade > 12) return "Adult";
      if (new Date().getMonth() < 6) {
        return (
          <span>
            {grade} <small>→ {grade2}</small>
          </span>
        );
      } else {
        return (
          <span>
            <small>{grade} →</small> {grade2}
          </span>
        );
      }
    },
  },
};

const billingRecordModel = {
  collection: "class-records",
  model: [
    { name: "date", type: "timestamp" },
    { name: "duration", type: "number" },
    { name: "instructor", type: "text" },
    { name: "isPresent", type: "boolean" },
    { name: "private", type: "boolean" },
    { name: "student_id", type: "text" },
    { name: "student_name", type: "text" },
    { name: "subject", type: "text" },
    { name: "topic", type: "text" },
  ],
  comparator: (a, b) => b.data().date.localeCompare(a.data().date),
};

export { groupModel, userModel, studentModel, billingRecordModel };
