import React, { useContext, useEffect, useState } from "react";
import { Badge, Col, Form, Modal, Row } from "react-bootstrap";
import { FaJava, FaJs, FaPython, FaUnderline, FaUnity, FaUser } from "react-icons/fa";
import { Link } from 'react-router-dom';
import RSelect from "react-select/async";
import CrudForm from "../../components/crud-table/form";
import { groupModel } from "../../components/crud-table/models/common-models";
import { DAYS_OF_WEEK } from "../../utils/constants";
import { fstore, serializeDoc } from "../../utils/fb";
import idCache from "../../utils/id-cache";
import { timeToDateObj, yearToGrade } from "../../utils/my-date-fns";
import SiteContext from "../../utils/site-context";
import "./group-schedule.scss";

const DAY_START = 10; // day starts at 8AM
const DAY_END = 12 + 11; // last hour to display
const dayToCol = (day) => {
  return DAYS_OF_WEEK.indexOf(day) + 2;
};
const timeToRow = (time, duration) => {
  let dateObj = timeToDateObj(time);
  let start = (dateObj.getHours() - DAY_START + 1) * 2;
  if (dateObj.getMinutes() >= 30) start += 1;
  let span = Math.ceil(duration / 30);
  return `${start} / span ${span}`;
};
const ampm = (hr) => ((hr - 1) % 12) + 1;

const getInstructorColor = (name) => {
  switch (name) {
    case "Daniel":
      return "success";
    case "Matt":
      return "danger";
    case "David":
      return "info";
    default:
      return "secondary";
  }
};

const getInstructorPos = (name) => {
  switch (name) {
    case "Daniel": return 2;
    case "Matt": return 1;
    case "David":
    default: return 0;
  }
};

const label_cache = {}; // to store RSelect value and labels

function groupSortWeight(a) {
  let day = DAYS_OF_WEEK.indexOf(a.data().day);
  let [, h, m, ampm] = /([0-9]{1,2}):?([0-9]{0,2})(am|pm)*/gi.exec(
    a.data().time
  );
  if (m) m = parseInt(m, 10);

  return (
    day * 1000 +
    (parseInt(h, 10) % 12) * 60 +
    (m || 0) +
    (ampm === "pm" ? 12 * 60 : 0)
  );
}

export default function GroupSchedule() {
  const [groups, setGroups] = useState([]);
  const [editGroup, setEditGroup] = useState(false);
  const { user } = useContext(SiteContext);

  const students = [];

  useEffect(() => {
    fstore
      .collection("groups")
      .get()
      .then((s) => s.docs)
      .then((groups) => {
        groups.sort((a, b) => groupSortWeight(a) - groupSortWeight(b));
        groups.forEach(g => {
          g.data().roster.forEach(st => label_cache[st.value] = st.label);
        })
        return groups;
      })
      .then(setGroups);
  }, []);

  async function onGroupUpdate(formData) {
    if (formData.roster === "") formData.roster = [];
    else if (!Array.isArray(formData.roster)) formData.roster = [formData.roster];

    formData.roster = formData.roster.map((id) => {
      return {
        value: id,
        label: label_cache[id]
      };
    });

    console.log(formData);
    await fstore.collection("groups").doc(editGroup.id).set(formData);
    let uDoc = { id: editGroup.id, data: () => formData };
    setEditGroup(uDoc);
    setGroups((prev) => prev.map((v) => (v.id === editGroup.id ? uDoc : v)));
  }

  async function onCreateGroup() {
    if (!user.isSuperAdmin) return;
    const data = {
      subject: "PYTHON",
      level: "1",
      time: "10am",
      private: false,
      temp: false,
      day: "🌞",
      duration: 60,
      instructor: "Daniel",
      roster: [],
    };
    const docRef = await fstore.collection("groups").add(data);
    const doc = { id: docRef.id, data: () => data };
    await idCache.addItem("groups", serializeDoc(doc));
    setGroups((prev) => [...prev, doc]);
  }

  function addStudent() {
    let name = window.prompt("Enter Student Name");
    if (name) {
      fstore.collection("students").add({
        email: '',
        is_active: false,
        name,
        phone: '',
        sid: null,
        detail: {}
      }).then(ref => window.open(`/admin/student/${ref.id}`));
    }
  }

  async function onDeleteGroup() {
    if (!user.isSuperAdmin) return;
    await fstore.collection("groups").doc(editGroup.id).delete();
    setGroups((prev) => prev.filter((v) => v.id !== editGroup.id));
  }


  return (
    <div className="container-fluid my-3">
      <h3 className="my-2">Schedule</h3>
      <div className="GroupScheduleSmall">
        <div className="time-cell"></div>
        {
          // time
          new Array(DAY_END - DAY_START).fill(0).map((_, j) => (
            <div
              key={j}
              className="time-cell d-flex justify-content-center align-items-center p-2"
              style={{
                gridColumn: `1`,
                gridRow: `${j * 2 + 2} / span 2`,
              }}
            >
              {ampm(j + DAY_START)}
            </div>
          ))
        }
        {
          // day header
          DAYS_OF_WEEK.map((d, i) => (
            <div
              key={d}
              style={{
                gridColumn: `${i + 2}`,
                cursor: "pointer",
              }}
              className="guide-cell"
            >
              {d}
            </div>
          ))
        }
        {
          // blank cells
          DAYS_OF_WEEK.map((_day, i) =>
            new Array(DAY_END - DAY_START).fill(0).map((_, j) => (
              <div
                key={i * 10 + j}
                className="guide-cell"
                style={{
                  gridColumn: `${i + 2}`,
                  gridRow: `${j * 2 + 2} / span 2`,
                }}
              >
                &nbsp;
              </div>
            ))
          )
        }
        {
          // groups
          groups
            // .filter((g) => g.data().time !== "0")
            .map((g, i) => {
              const gd = g.data();
              let height = '100%';
              if (parseInt(gd.duration, 10) === 45) height = '75%';

              return (
                <div
                  key={g.id}
                  className={`
                        group py-1 d-flex flex-wrap justify-content-center align-items-center 
                        bg-${getInstructorColor(gd.instructor)}${gd.temp ? "-temp" : ""}
                    `}
                  style={{
                    gridColumn: dayToCol(gd.day),
                    gridRow: timeToRow(gd.time, gd.duration),
                    cursor: "pointer",
                    width: "33%",
                    position: "relative",
                    left: (getInstructorPos(gd.instructor) * 33) + "%",
                    height
                  }}
                  onClick={() => user.isAdmin && setEditGroup(g)}
                >
                  <div className="d-flex justify-content-center align-items-center cell-bg">
                    {(() => {
                      switch (gd.subject) {
                        case "GAME":
                          return <FaUnity />;
                        case "USACO":
                          return <FaUnderline />;
                        case "WEB":
                          return <FaJs />;
                        case "JAVA":
                          return <FaJava />;
                        case "PYTHON":
                          return <FaPython />;
                        default:
                          return <></>;
                      }
                    })()}
                  </div>
                  {gd.roster &&
                    gd.roster.map((st, ii) => (
                      // <div>{st.label.replace(/\s\(.*\)/g, "")}</div>
                      <div key={ii} className="mx-1">
                        {st.label.split(" ")[0]}
                      </div>
                    ))}
                  <div>{parseInt(gd.duration, 10) % 30 !== 0 && `(${gd.duration}')`}</div>
                </div>
              );
            })
        }
      </div>

      {user.isSuperAdmin && (
        <div className="text-right mt-2">
          <button className="btn btn-primary" onClick={onCreateGroup}>
            + ADD Group
          </button>

          <button className="btn btn-primary ml-2" onClick={addStudent}>
            + ADD Student <FaUser />
          </button>
        </div>
      )}

      {
        user.isSuperAdmin && (
          <CrudForm
            show={!!editGroup}
            hide={() => setEditGroup(undefined)}
            doc={editGroup}
            onSave={onGroupUpdate}
            onDelete={onDeleteGroup}
            model={groupModel.model}
            key={editGroup}
          >
            {editGroup && (
              <RosterFormGroup roster={editGroup.data().roster} students={students} />
            )}
          </CrudForm>
        )
      }
      {
        user.isAdmin && !user.isSuperAdmin && (
          <Modal show={!!editGroup}
            onHide={() => setEditGroup(undefined)} key={editGroup}>
            <Modal.Header closeButton>Roster</Modal.Header>
            <Modal.Body>
              {editGroup && (
                <div className="mt-2">
                  <Badge>Links</Badge>
                  {
                    editGroup.data().roster.map(({ label, value }) => <Link key={value} className="mr-2 badge" to={`/admin/student/${value}`}>{label}</Link>)
                  }
                </div>
              )}
            </Modal.Body>
          </Modal>
        )
      }

    </div>
  );
}

let adminStudentSearchedName = '';
function RosterFormGroup({ roster, students }) {

  function onStudentSearch(inputValue) {
    adminStudentSearchedName = inputValue;

    return new Promise((res) => {
      if (!inputValue || inputValue.length < 3) {
        res([]);
        return;
      }

      setTimeout(() => {
        // if input value hasn't changed for 0.5 second
        if (inputValue === adminStudentSearchedName) {

          fstore.collection("students")
            .where('name', ">=", inputValue)
            .where('name', '<=', inputValue + "zzzz")
            .get()
            .then(s => s.docs)
            .then(s => s.map(ss => {
              let label = `${ss.data().name} (${yearToGrade(ss.data().detail.graduation_year)})`;
              label_cache[ss.id] = label;
              return { label, value: ss.id }
            }))
            .then(res);
        }
      }, 500);
    });
  }

  return (
    <>
      <Form.Group key="roster" as={Row}>
        <Form.Label column sm="3">
          Roster
        </Form.Label>
        <Col sm={9}>
          <RSelect
            defaultValue={roster}
            // options={options}
            loadOptions={onStudentSearch}
            name="roster"
            isMulti
          />
          <div className="mt-2">
            <Badge>Links</Badge>
            {
              roster.map(({ label, value }) => <Link key={value} className="mr-2 badge" to={`/admin/student/${value}`}>{label}</Link>)
            }
          </div>
        </Col>
      </Form.Group>
    </>
  );
}