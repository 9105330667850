import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';


export default function AlgoForm({ show, onHide, onCreate }) {

  let [loading, setLoading] = useState(false);

  function onSubmit(e) {
    setLoading(true);
    e.preventDefault();
    const formData = Object.fromEntries(new FormData(e.target));
    e.target.reset();

    onCreate(formData).then(() => { setLoading(false); onHide() });
  }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        Submit your code and analysis
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Form.Label>source</Form.Label>
          <Form.Control as="select" name="source" required>
            <option value="">choose...</option>
            <option>USACO GOLD</option>
            <option>USACO SILVER</option>
            <option>USACO BRONZE</option>
            <option>Code Forces</option>
            <option>SPOJ</option>
            <option>Hackerrank</option>
            <option>Leetcode</option>
            <option>Codingbat</option>
          </Form.Control>
          <Form.Label>Problem Name</Form.Label>
          <Form.Control name="problem" placeholder="Milk Measurement" required />
          <Form.Label>Analysis</Form.Label>
          <TextareaAutosize name="analysis" className="form-control" rows={10} cols={50}></TextareaAutosize>
          <Form.Label>Code</Form.Label>
          <TextareaAutosize name="code" className="form-control" rows={10} cols={50}></TextareaAutosize>
          <Form.Label>Does it work?</Form.Label>
          <Form.Control as="select" name="completedness">
            <option value="">choose...</option>
            <option>All Credit</option>
            <option>Correct but TLE</option>
            <option>Partially correct</option>
            <option>Incorrect</option>
            <option>Incomplete</option>
          </Form.Control>
          <hr />
          <Button variant="primary" type="submit" disabled={loading}>{loading ? 'Loading...' : 'Submit'}</Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}