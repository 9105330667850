import { formatDistance } from 'date-fns';
import { subMonths } from 'date-fns/esm';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FaGamepad, FaHtml5, FaJava, FaJsSquare, FaPython, FaTrophy, FaUnity } from 'react-icons/fa';
import styled from 'styled-components';
import ContainerWithHeader from '../../components/ContainerWithHeader';
import { fstore } from '../../utils/fb';
import SiteContext from '../../utils/site-context';
import QnaModal from './components/qna-modal';
import { Link } from 'react-router-dom';

function QnA() {

  const [questions, setQuestions] = useState([]);
  const [showEditor, setShowEditor] = useState(false);

  const { user } = useContext(SiteContext)
  console.log("user", user);

  useEffect(() => {
    fstore.collection("questions")
      .where("postedDate", ">", subMonths(new Date(), 1))
      .get()
      .then(s => s.docs)
      .then(setQuestions);
  }, []);

  async function onModalFormSubmit(formData) {

    const { topic, title, isPrivate, body } = formData;

    const data = {
      uid: user.uid,
      solved: false,
      postedDate: new Date(),
      detail: {
        title, topic, isPrivate: !!isPrivate,
        displayName: user.displayName
      }
    }

    const { id } = await fstore.collection("questions").add(data);
    await fstore.collection("questions-text").doc(id).set({ text: body, uid: user.uid });
    const doc = await fstore.collection("questions").doc(id).get();
    setQuestions(qs => [...qs, doc]);
    setShowEditor(false);
  }

  return <ContainerWithHeader title="QnA">
    <div className="text-right">
      <Button onClick={() => setShowEditor(true)}>Post a new question</Button>
    </div>
    <h3>My Questions {'👩‍💻'}</h3>
    <QuestionList ar={questions.filter(q => q.data().uid === user.uid)} />
    <h3 className="mt-5">All Question {'👨‍💻'}</h3>
    <QuestionList ar={questions.filter(q => q.data().uid !== user.uid)} />

    <QnaModal show={showEditor} onHide={() => setShowEditor(false)} onModalFormSubmit={onModalFormSubmit} />
  </ContainerWithHeader>
}

const QnaGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;

  > * {
    border-bottom: 1px solid #eee;
    padding: 10px;
  }
`;

function getTopicLogo(topic) {
  switch (topic) {
    case 'java': return <FaJava />;
    case 'python': return <FaPython />;
    case 'html': return <FaHtml5 />;
    case 'javascript': return <FaJsSquare />;
    case 'competition': return <FaTrophy />;
    case 'unity': return <FaUnity />;
    case 'pygame': return <FaGamepad />;
    default: return <></>
  }
}

function QuestionList({ ar }) {
  if (ar.length === 0) return "There is no recent questions";
  return (
    <QnaGrid>
      {
        ar.map(v => <Fragment key={v.id}>
          <div>
            <Alert variant={v.data().solved ? "success" : "secondary"} style={{ display: 'inline-block' }}>{v.data().numAnswers || 0}</Alert>
          </div>
          <div className="d-flex flex-column justify-content-start">
            <h4>{getTopicLogo(v.data().detail.topic)} {v.data().detail.title}</h4>
            <div>
              Posted by: <span className="text-primary">{v.data().detail.isPrivate ? "Anonymous" : v.data().detail.displayName}</span>
            </div>
          </div>
          <div className="text-right">
            <div><Link to={`/learn/qna/${v.id}`}><Button variant="secondary" size="sm">View</Button></Link></div>
            <div>{formatDistance(new Date(), v.data().postedDate.toDate())} ago</div>
          </div>
        </Fragment>)
      }
    </QnaGrid>
  )
}

export { QnA, QuestionList };