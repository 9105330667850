import { format, isAfter, parseISO } from "date-fns";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Alert, Badge, Button, Form, FormControl, InputGroup, Modal } from "react-bootstrap";
import { FaCheck, FaCheckDouble, FaTimes } from "react-icons/fa";
import { Link, useHistory } from 'react-router-dom';
import ContainerWithHeader from "../../components/ContainerWithHeader";
import TextBoxInput from "../../components/from-related/textbox-input";
import { DAYS_OF_WEEK } from "../../utils/constants";
import { fstore } from "../../utils/fb";
import { timeToDateObj } from "../../utils/my-date-fns";
import { updateFieldPrompt } from "../../utils/my-lodash";
import { GridTable } from "../../utils/styled-elements";
import "./daily-report.scss";

const yymm = 'yyyy-MM-dd';

export default function DailyReport({ match }) {

  const date_param = match.params.date || format(new Date(), yymm);
  const today = useMemo(() => parseISO(date_param), [date_param]);
  const { push } = useHistory();

  const [groups, setGroups] = useState([]);
  const [records, setRecords] = useState(undefined); // students with records
  const [showSamples, setShowSamples] = useState(false);

  useEffect(() => {
    fstore.collection("groups").get().then(s => s.docs).then(docs => {
      docs.sort((a, b) => isAfter(timeToDateObj(a.data().time), timeToDateObj(b.data().time)) ? 1 : -1)
      setGroups(docs);
    })
    fstore
      .collection("class-records")
      .where("date", "==", today)
      .get()
      .then((ss) => ss.docs)
      .then(setRecords);
  }, [date_param, today]);

  if (!records)
    return (
      <ContainerWithHeader title={"Daily Report 📆"}>
        Loading....
      </ContainerWithHeader>
    );

  const todaysGroupsIds = groups.filter(g => g.data().day === DAYS_OF_WEEK[today.getDay()]).map(g => g.id);
  const nonClassRecords = records
    .filter(r => !todaysGroupsIds.includes(r.data().groupId))
    .map(rec => <RecordRow key={rec.id} rec={rec} setRecords={setRecords} />)

  return (
    <ContainerWithHeader title={"Daily Report 📆"} contentClassName="DailyReport">
      <div className="d-flex">
        <div className="flex-grow-1">
          <InputGroup>
            <InputGroup.Prepend>
              <Button onClick={() => push(`/admin/daily-report`)}>Today</Button>
            </InputGroup.Prepend>
            <FormControl
              type="date"
              style={{ fontSize: "1.1em" }}
              value={date_param}
              onChange={(ev) => {
                let d = parseISO(ev.target.value)
                if (!isNaN(d)) push(`/admin/daily-report/${ev.target.value}`)
              }}
            />
          </InputGroup>
        </div>
        <div className="btn-a ml-3" onClick={() => setShowSamples(true)}>
          View Samples
        </div>
      </div>
      {/* groups */}
      <GridTable style={{ fontSize: "0.9em" }} size="sm" className="mt-3 daily-report-table" numCols={6}>
        {groups
          .filter(g => g.data().day === DAYS_OF_WEEK[today.getDay()])
          .map((g) => (
            <GroupBox
              key={g.id + format(today, "yyyyMMdd")}
              group={g}
              records={records.filter(r => r.data().groupId === g.id)}
              setRecords={setRecords}
              today={today}
            />
          ))}
      </GridTable>

      {
        nonClassRecords.length > 0 && (
          <>
            <h3>Non-Class Records</h3>
            <div className="records">
              {nonClassRecords}
            </div>
          </>
        )
      }


      <Samples show={showSamples} onHide={() => setShowSamples(false)} />
    </ContainerWithHeader>
  );
}

function RecordRow({ rec, setRecords }) {
  const { /*date,*/ stid, /*groupId*/ } = rec.data();
  const { did_homework, is_present, /* is_private,*/ minutes, next_homework, personal_notes, /*notes*/ } = rec.data().detail;
  // const { user } = useContext(SiteContext);
  const [name, setName] = useState(rec.data().detail.name); // we need to fetch name for old records

  useEffect(() => {
    if (rec.exists && !rec.data().detail.name) {
      fstore.collection("students").doc(rec.data().stid).get().then(student => {
        if (student.exists) {
          setName(student.data().name);
        }
      });
    }
  }, [rec]);

  async function updateField(field, value, isDetail = false) {
    if (isDetail)
      await fstore.collection("class-records").doc(rec.id).update({ detail: { ...rec.data().detail, [field]: value } });
    else
      await fstore.collection("class-records").doc(rec.id).update({ [field]: value });

    let doc = await fstore.collection("class-records").doc(rec.id).get();
    setRecords(recs => recs.map(r => (r.id === rec.id) ? doc : r));
  }

  async function onPrompt(field, isDetail) {
    updateFieldPrompt({ collectionName: "class-records", doc: rec, field, isDetail }).then((doc) => {
      if (doc) setRecords(recs => recs.map(r => (r.id === rec.id) ? doc : r));
    })
  }

  function deleteme() {
    fstore.collection("class-records").doc(rec.id).delete().then(() => {
      setRecords(recs => recs.filter(r => r.id !== rec.id));
    });
  }

  return (
    <>

      <div>
        {
          is_present ?
            <Button size="sm" onClick={() => updateField("is_present", false, true)} variant="success">P</Button> :
            <Button size="sm" onClick={() => updateField("is_present", true, true)} variant="danger">A</Button>
        }
      </div>
      <div>
        <Badge variant="secondary" onClick={() => onPrompt("minutes", true)}>{minutes} min</Badge>
      </div>
      <div><Link to={`/admin/student/${stid}`} target="_blank">{name}</Link></div>
      <div>
        <Button size="sm" onClick={() => updateField("did_homework", -1, true)} variant={did_homework === -1 ? "danger" : ""}><FaTimes /></Button>
        <Button size="sm" onClick={() => updateField("did_homework", 0, true)} variant={did_homework === 0 ? "secondary" : ""}>N/A</Button>
        <Button size="sm" onClick={() => updateField("did_homework", 1, true)} variant={did_homework === 1 ? "success" : ""}><FaCheck /></Button>
        <Button size="sm" onClick={() => updateField("did_homework", 2, true)} variant={did_homework === 2 ? "warning" : ""}><FaCheckDouble /></Button>
      </div>
      <div>
        <TextBoxInput type="textarea" value={personal_notes} updateValue={v => updateField("personal_notes", v, true)} placeHolder="Person Notes" />
        <TextBoxInput type="textarea" value={next_homework} updateValue={v => updateField("next_homework", v, true)} placeHolder="Homework" />
      </div>
      <div><Button variant="danger" size="sm" onClick={deleteme}>X</Button></div>


    </>
  )
}

function GroupBox({ group, records, setRecords, today }) {
  const [loading, setLoading] = useState(false);
  const gd = group.data();

  function createClassRecord(e) {
    e.preventDefault();
    setLoading(false);
    const pAr = [];
    gd.roster.forEach(({ label: name, value: sid }, i) => {
      const data = {
        stid: sid,
        date: today,
        groupId: group.id,
        detail: {
          name: name,
          price: gd.price,
          instructor: gd.instructor,
          subject: gd.subject,
          is_private: !!gd.private,
          minutes: gd.duration,
          is_present: true,
          next_homework: e.target.homework.value,
          notes: e.target.notes.value,
          did_homework: 0,
          quiz_score: 0,
          created_at: new Date(),
          updated_at: new Date(),
        }
      }

      let p = fstore.collection("class-records").add(data)
        .then(({ id }) => ({ id, data: () => data }))
        .catch(e => console.log(e));
      pAr.push(p);
    });

    Promise.all(pAr).then(entries => {
      // console.log(entries[0].data())
      setRecords(prev => [...prev, ...entries])
      setLoading(true);
    }).catch(e => console.log(e))
  }

  return (
    <>
      <div className="bg-dark text-white span-row">
        <div className="p-1 pl-2 d-flex justify-content-between align-items-center">
          <div>
            <span className="mr-2">{gd.day}</span>
            <span className="mr-2">
              {format(timeToDateObj(gd.time), "h:mm")}
            </span>
            <span className="mr-2">
              {gd.subject}-{gd.level}
            </span>
            <span className="mr-2">
              <Badge variant={getInstructorColor(gd.instructor)}>
                {gd.instructor}
              </Badge>
            </span>
            {gd.private && (
              <span>
                <Badge variant="light">Private</Badge>
              </span>
            )}
          </div>
          <div>
            {gd.roster.map(({ label }) => <span className="mr-2 ">{label}</span>)}
          </div>
        </div>
      </div>

      {/* form */}
      {records.length === 0 && (<>
        <Form onSubmit={createClassRecord} className="span-row">
          <Form.Control as="textarea" placeholder="class report" rows={3} name="notes" />
          <Form.Control as="textarea" placeholder="next homework" rows={2} name="homework" />
          <div className="d-flex justify-content-between mt-2">

            <div className="flex-grow-1"></div>
            <Button size="sm" type="submit" disabled={loading}>{loading ? "..." : "Create reports"}</Button>
          </div>
        </Form>
      </>)}

      {
        records.length > 0 && (
          <div className="span-row white-space-pre">{records[0].data().detail.notes}</div>
        )
      }

      {
        gd.roster.map(({ value: sid, label: name }, i) => {
          let rec = records.find(r => r.data().stid === sid);
          if (rec) return (<RecordRow key={sid + rec.id} rec={rec} setRecords={setRecords} />);
          else return <Fragment />
        })
      }
    </>
  );
}

function getInstructorColor(name) {
  switch (name) {
    case "Matt":
      return "danger";
    case "David":
      return "info";
    case "Daniel":
      return "success";
    default:
      return "light";
  }
}

function Samples({ show, onHide }) {
  return <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>Samples</Modal.Header>
    <Modal.Body>
      <Alert variant="secondary">from last week, students have been designing automated racers that will battle against each other to see who reaches the finish line first. The purpose of this project is to introduce an idea of how numbers work in programming and how it can influence things in our porjects. We are expecting this project ot be done by next week.</Alert>
    </Modal.Body>
  </Modal>
}
