import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyARBy-h7TwY2ICzAd0IVS7-sNAYWBbUKsY",
  authDomain: "btree-v1.firebaseapp.com",
  databaseURL: "https://btree-v1.firebaseio.com",
  projectId: "btree-v1",
  storageBucket: "btree-v1.appspot.com",
  messagingSenderId: "415299248646",
  appId: "1:415299248646:web:811d5acbd0890adf9f5987",
  measurementId: "G-2HHNS063ZK",
};

const fb = firebase.initializeApp(firebaseConfig);
const secondaryApp = firebase.initializeApp(firebaseConfig, "secondary");
const fstore = fb.firestore();
const fauth = fb.auth();
const fupload = fb.storage();
const github_provider = new firebase.auth.GithubAuthProvider();
const google_provider = new firebase.auth.GoogleAuthProvider();

const serializeDoc = (doc: firebase.firestore.DocumentData) => {
  // id: doc.id,
  // ...doc.data(),
  const data = doc.data();
  Object.keys(data).forEach((key) => {
    if (data[key] instanceof firebase.firestore.Timestamp) {
      data[key] = data[key].toDate();
    }
  });
  return { ...data, id: doc.id };
};
const unserializeDoc = (obj: any) => {
  const { id, ...data } = obj;
  Object.keys(data).forEach((key) => {
    if (data[key] instanceof Date) {
      data[key] = firebase.firestore.Timestamp.fromDate(data[key]);
    }
  });
  return { id, data: () => data };
};
const serializeQuery = (ss: firebase.firestore.QuerySnapshot) =>
  ss.docs.map(serializeDoc);
const unserializeQuery = (ar: any[]) => ar.map(unserializeDoc);
const Timestamp = firebase.firestore.Timestamp;



export {
  firebase,
  fstore,
  fauth,
  fupload,
  github_provider,
  google_provider,
  serializeQuery,
  unserializeQuery,
  serializeDoc,
  unserializeDoc,
  Timestamp,
  secondaryApp
};
