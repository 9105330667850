import { format, startOfMonth, subMonths } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { Badge, Button, ButtonGroup, Card, Table } from 'react-bootstrap';
import { FaCheck, FaCheckDouble, FaPencilAlt, FaStar, FaTimes } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import { InputboxDate } from '../../components/from-related/inputbox_date';
import TextBoxInput from '../../components/from-related/textbox-input';
import { fstore } from '../../utils/fb';
import { updateField, updateFieldPrompt } from '../../utils/my-lodash';
import SiteContext from '../../utils/site-context';

function getSortWeight(rec) {
  let data = rec.data();
  let rectype = rec.rectype;
  let detail = data.detail;

  let w = parseInt(format(data.date.toDate(), "yyyyMMdd"), 10);
  // w = w * 10 + (rectype === 'billing' ? 1 : 0);

  if (rectype === 'billing') {
    switch (detail.type) {
      case 'payment': w = w * 10 + 3; break;
      case 'invoice': w = w * 10 + 2; break;
      case 'balance': w = w * 10 + 1; break;
      default: break;
    }
  } else {
    w = w * 10 + 4;
  }

  return w;
}

export default function StudentClassRecords({ student }) {

  let [classAr, setClassAr] = useState([]);
  let [billingAr, setBillingAr] = useState([]);
  let [records, setRecords] = useState([]);   // class + billing
  let [balance, setBalance] = useState([]);
  let [numMonths, setNumMonths] = useState(2);

  const { user } = useContext(SiteContext);

  useEffect(() => {
    if (!student) return;
    const limit = startOfMonth(subMonths(new Date(), numMonths));
    fstore.collection("class-records")
      .where("stid", "==", student.id)
      .where("date", ">=", limit)
      .orderBy('date', 'desc')
      .get()
      .then(s => s.docs)
      .then(setClassAr)
      .catch(e => console.log(e));

    if (user.isSuperAdmin)
      fstore.collection("billing-records")
        .where("stid", "==", student.id)
        .where("date", ">=", limit)
        .orderBy('date', 'desc')
        .get()
        .then(s => s.docs)
        .then(setBillingAr)
        .catch(e => console.log(e));

  }, [student, numMonths, user.isSuperAdmin]);

  useEffect(() => {
    let ar = [...classAr.map(d => ({ id: d.id, rectype: 'class', data: () => d.data() }))
      , ...billingAr.map(d => ({ id: d.id, rectype: 'billing', data: () => d.data() }))];
    ar.sort((a, b) => getSortWeight(a) - getSortWeight(b));

    let balance = new Array(ar.length);
    for (let i = 0; i < ar.length; i++) {
      let v = ar[i];
      let data = ar[i].data();
      let detail = data.detail;
      let prevBal = i === 0 ? 0 : balance[i - 1];

      if (v.rectype === 'billing') {
        switch (detail.type) {
          case 'balance': balance[i] = parseInt(detail.amt, 10); break;
          case 'payment': balance[i] = prevBal + parseInt(detail.amt, 10); break;
          default: balance[i] = prevBal
        }
      } else {

        if (typeof detail.price !== 'number') {
          // old records
          if (!detail.is_present)
            balance[i] = prevBal;
          else if (detail.is_private === 1)
            balance[i] = prevBal + parseInt(detail.minutes, 10) * parseInt(detail.base_price, 10) / 60;
          else
            balance[i] = prevBal + parseInt(detail.minutes, 10) * 40 / 60;
        } else {
          balance[i] = prevBal + parseInt(detail.price, 10);
        }
      }
    }
    ar.reverse();
    balance.reverse();

    setRecords(ar);
    setBalance(balance);
  }, [classAr, billingAr])

  const addClassRecord = async () => {
    const data = {
      stid: student.id,
      date: new Date(),
      groupId: -1,
      detail: {
        name: student.data().name,
        price: 80,
        instructor: "Daniel",
        subject: "-",
        is_private: false,
        minutes: 120,
        is_present: true,
        next_homework: "-",
        notes: "-",
        did_homework: 0,
        quiz_score: 0,
        created_at: new Date(),
        updated_at: new Date(),
      }
    }

    let { id } = await fstore.collection("class-records").add(data);
    let doc = await fstore.collection("class-records").doc(id).get();
    console.log("added id", id);
    setClassAr(p => [...p, doc]);
  }

  const addBillingRecord = async (type) => {
    let date = new Date();
    let { id } = await fstore.collection("billing-records").add({
      date, stid: student.id, detail: {
        amt: 0, created_at: new Date(), type
      }
    });
    let doc = await fstore.collection("billing-records").doc(id).get();
    console.log("added id", id);
    setBillingAr(p => [...p, doc]);
  }

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <div>
          <span>Class Records</span>
          {
            user.isSuperAdmin && (
              <ButtonGroup className="ml-5">
                <Button size="sm" variant={numMonths === 2 ? "primary" : "outline-primary"} onClick={() => setNumMonths(2)}>View 3 months</Button>
                <Button size="sm" variant={numMonths === 6 ? "primary" : "outline-primary"} onClick={() => setNumMonths(6)}>View 6 months</Button>
                <Button size="sm" variant={numMonths === 12 ? "primary" : "outline-primary"} onClick={() => setNumMonths(12)}>View 12 months</Button>
                <Button size="sm" variant={numMonths === 99 ? "primary" : "outline-primary"} onClick={() => setNumMonths(99)}>View All</Button>
              </ButtonGroup>
            )
          }
        </div>
        {
          user.isSuperAdmin && (
            <div>
              <Button variant="success" size="sm" onClick={() => addClassRecord()} className="mr-1">+ class</Button>
              <Button size="sm" onClick={() => addBillingRecord('payment')} className="mr-1">+ payment</Button>
              <Button size="sm" onClick={() => addBillingRecord('invoice')} className="mr-1">+ invoice</Button>
              <Button size="sm" onClick={() => addBillingRecord('balance')}>+ balance</Button>
            </div>
          )
        }
      </Card.Header>
      <Card.Body>
        {!student && "No student record exist"}
        <RecordTable records={records} balance={balance} setClassAr={setClassAr} setBillingAr={setBillingAr} />
      </Card.Body>
    </Card>
  )
}

function RecordTable({ records, balance, setClassAr, setBillingAr }) {

  const { user } = useContext(SiteContext);
  const columns = ",Date,Class Notes,H/W" + (user.isSuperAdmin ? ",amt,bal" : "");

  return (
    <div>
      <Table bordered>
        <thead>
          <tr>
            {columns.split(",").map((k) => (
              <th key={k}>{k}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {records.map((rec, i) => {
            if (rec.rectype === 'billing') return <BillingRecordRow rec={rec} key={rec.id} balance={balance[i]} setBillingAr={setBillingAr} />
            else return <ClassRecordRow setClassAr={setClassAr} rec={rec} key={rec.id} balance={balance[i]} />
          })}
        </tbody>
      </Table>
    </div>
  );
}

function BillingRecordRow({ rec, balance, setBillingAr }) {
  const onPrompt = async (field, isDetail) => {
    let updatedDoc = await updateFieldPrompt({ collectionName: "billing-records", doc: rec, field, isDetail })
    if (!updatedDoc) return;
    setBillingAr(prev => prev.map(v => v.id === rec.id ? updatedDoc : v));
  }

  const onDateChange = async (value) => {
    let updatedDoc = await updateField({ collectionName: "billing-records", doc: rec, field: "date", value })
    setBillingAr(prev => prev.map(v => v.id === rec.id ? updatedDoc : v));
  }

  const deleteEntry = async () => {
    if (!window.confirm("sure?")) return;
    await fstore.collection("billing-records").doc(rec.id).delete();
    setBillingAr(prev => prev.filter(v => v.id !== rec.id));
  }

  let { /*stid,*/ date, detail } = rec.data();
  let { amt, note, /*payment_type,*/ type } = detail;

  return <tr className="bg-light">
    {/* <td onClick={() => updateDetailField("date", false)} className="highlight-onhover">{format(date.toDate(), 'yyyy-MM-dd')}</td> */}
    <td className="revealOnHoverTd">
      <Button variant="danger" size="sm" onClick={() => deleteEntry()}>x</Button>
    </td>
    <td>
      <InputboxDate enabled={true} id={rec.id} value={date} updateValue={onDateChange} />
    </td>
    <td colSpan={2}>

      <Badge variant={type === 'payment' ? 'success' : (type === 'balance' ? "info" : "secondary")}>{type}</Badge>
      {type === 'payment' &&
        (<span onClick={() => onPrompt("note", true)} className="highlight-onhover">
          <Badge>Note</Badge>
          {note || "-"}
        </span>)
      }
    </td>
    <td onClick={() => onPrompt("amt", true)} className="highlight-onhover">{amt}</td>
    <td>{type === 'invoice' ? '-' : balance}</td>
  </tr>
}

function ClassRecordRow({ rec, balance, setClassAr }) {

  const [editMode, setEditMode] = useState(false);
  const { user } = useContext(SiteContext);

  function promptUpdate(field, isDetail) {
    if (!user.isSuperAdmin) return;
    updateFieldPrompt({ collectionName: "class-records", doc: rec, field, isDetail }).then((newRec) => {
      if (newRec) setClassAr(prev => prev.map(r => r.id === rec.id ? newRec : r));
    })
  }

  async function onValueUpdate(field, value, isDetail) {
    if (!user.isAdmin) return;
    let newRec = await updateField({ collectionName: "class-records", doc: rec, field, value, isDetail })
    if (newRec) setClassAr(prev => prev.map(r => r.id === rec.id ? newRec : r));
  }

  const onDateChange = async (value) => {
    if (!user.isSuperAdmin) return;
    let updatedDoc = await updateField({ collectionName: "class-records", doc: rec, field: "date", value })
    setClassAr(prev => prev.map(v => v.id === rec.id ? updatedDoc : v));
  }

  const deleteEntry = async () => {
    if (!user.isSuperAdmin) return;
    if (!window.confirm("sure?")) return;
    await fstore.collection("class-records").doc(rec.id).delete();
    setClassAr(prev => prev.filter(v => v.id !== rec.id));
  }

  return (<tr>
    <td className="revealOnHoverTd">
      {
        user.isSuperAdmin && <Button variant="danger" size="sm" onClick={() => deleteEntry()}>x</Button>
      }

    </td>
    <td>
      <div><InputboxDate enabled={user.isSuperAdmin} id={rec.id} value={rec.data().date} updateValue={onDateChange} /></div>
      <div className="d-flex align-items-center mt-3" style={{ opacity: 0.7 }}>
        {
          rec.data().detail.is_present ?
            <Badge className="pointer" onClick={() => onValueUpdate("is_present", false, true)} variant="success">Present</Badge> :
            <Badge className="pointer" onClick={() => onValueUpdate("is_present", true, true)} variant="danger">Absent</Badge>
        }
        {
          !!rec.data().detail.is_private &&
          <Badge variant="info" className="ml-2 pointer" onClick={() => onValueUpdate("is_private", false, true)}>Private</Badge>
        }
        {
          user.isAdmin && !rec.data().detail.is_private &&
          <Badge variant="info" className="ml-2 pointer opacity-50" onClick={() => onValueUpdate("is_private", true, true)}>Group</Badge>
        }

        {
          rec.data().detail.minutes > 0 && <Badge className="ml-2 pointer" variant="secondary" onClick={() => promptUpdate("minutes", true)}>{rec.data().detail.minutes} min</Badge>
        }
      </div>
    </td>

    <td>
      {editMode ? (
        <>
          <label>notes</label>
          <TextBoxInput
            type="textarea" value={rec.data().detail.notes}
            updateValue={v => onValueUpdate("notes", v, true)} />

          <label>personal notes (visible to parents)</label>
          <TextBoxInput
            type="textarea" value={rec.data().detail.personal_notes}
            placeholder="person notes"
            updateValue={v => onValueUpdate("personal_notes", v, true)} />

          <label>next homework</label>
          <TextBoxInput
            type="textarea" value={rec.data().detail.next_homework}
            placeholder="next_homework"
            updateValue={v => onValueUpdate("next_homework", v, true)} />

        </>
      ) : (
          <>
            {/* <div style={{ whiteSpace: 'pre-wrap' }}>{rec.data().detail.notes}</div> */}
            <div>
              <ReactMarkdown source={rec.data().detail.notes} />
            </div>

            {
              rec.data().detail.personal_notes && (user.isParent || user.isAdmin) && (
                <div style={{ background: "#f7f7f7" }} className="rounded p-3 my-3">
                  <div className="white-space-pre" dangerouslySetInnerHTML={{ __html: rec.data().detail.personal_notes }}></div>
                </div>
              )
            }

            {rec.data().detail.next_homework &&
              (<div className="mt-3"><Badge className="mr-2">Next Homework</Badge>{rec.data().detail.next_homework || ' - '}</div>)}

          </>
        )}
      {user.isSuperAdmin && (
        <div className="d-flex justify-content-end">
          <Button size="sm" variant={editMode ? "primary" : "outline-primary"} onClick={() => setEditMode(v => !v)}><FaPencilAlt /></Button>
        </div>
      )}
    </td>
    <td>
      {
        user.isAdmin && (
          <div className="d-flex">
            <Button size="sm" onClick={() => onValueUpdate("did_homework", -1, true)} variant={rec.data().detail.did_homework === -1 ? "danger" : ""}><FaTimes /></Button>
            <Button size="sm" onClick={() => onValueUpdate("did_homework", 0, true)} variant={rec.data().detail.did_homework === 0 ? "secondary" : ""}>N/A</Button>
            <Button size="sm" onClick={() => onValueUpdate("did_homework", 1, true)} variant={rec.data().detail.did_homework === 1 ? "success" : ""}><FaCheck /></Button>
            <Button size="sm" onClick={() => onValueUpdate("did_homework", 2, true)} variant={rec.data().detail.did_homework === 2 ? "warning" : ""}><FaCheckDouble /></Button>
          </div>
        )
      }


      {
        (() => {
          if (user.isAdmin) return undefined;
          switch (rec.data().detail.did_homework) {
            case -1:
              return <div className="d-flex"><Badge variant="danger"><FaTimes /></Badge><Badge>Unsatisfactory</Badge></div>
            case 1:
              return <div className="d-flex"><Badge variant="success"><FaCheck /></Badge><Badge>Good</Badge></div>
            case 2:
              return <div className="d-flex"><Badge variant="primary"><FaStar /><FaStar /></Badge><Badge>Great!</Badge></div>
            default:
              return <></>
          }
        })()
      }

    </td>


    {
      user.isSuperAdmin && (
        <>
          <td className="highlight-onhover" onClick={() => promptUpdate("price", true)}>{rec.data().detail.price}
          </td>
          <td>{balance}</td>
        </>
      )
    }
  </tr >)
}