import React from 'react';
import { Container } from 'react-bootstrap';
import GroupSchedule from './group-schedule';

export default function Dashboard() {
    return <><GroupSchedule /><hr />
        <Container className="mt-2 mb-5">
            <iframe title="cal" src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23039BE5&amp;ctz=America%2FLos_Angeles&amp;src=YnRyZWVjb2RlQGdtYWlsLmNvbQ&amp;src=ZW4udXNhI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23039BE5&amp;color=%230B8043&amp;showNav=0&amp;showTitle=0&amp;showDate=1&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;showTz=0&amp;mode=MONTH" style={{ borderWidth: 0 }} width="100%" height="600" frameborder="0" scrolling="no"></iframe>
        </Container>
    </>
}
