import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import styled from "styled-components";
import "./crud-table.scss";
import CrudForm from "./form";
import { serializeDoc, serializeQuery, unserializeQuery } from "../../utils/fb";
import idCache from "../../utils/id-cache";

const CrudTable = ({
  collection,
  fs,
  model,
  comparator,
  tableCells,
  customRender = {},
}) => {
  const [docs, setDocs] = useState([]);
  const [sortedDocs, setSortedDocs] = useState([]);
  const [editDoc, setEditDoc] = useState(undefined);

  const init = useCallback(
    async (forceRefresh) => {
      idCache
        .get(
          collection,
          () => fs.collection(collection).get().then(serializeQuery),
          {
            forceRefresh,
            minutes: 24 * 60,
          }
        )
        .then(unserializeQuery)
        .then(setDocs);
    },
    [collection, fs]
  );

  useEffect(() => {
    init(false);
  }, [init]);

  useEffect(() => {
    let sdocs = [...docs];

    if (comparator) {
      setSortedDocs(sdocs.sort(comparator));
    } else {
      setSortedDocs(sdocs);
    }
  }, [docs, comparator]);

  const onAddClick = () => {
    setEditDoc({});
  };

  const deleteDoc = async (docid) => {
    await fs.collection(collection).doc(docid).delete();
    await idCache.deleteItem(collection, docid);
    setDocs(docs.filter((d) => d.id !== docid));
  };

  const onSave = async (formData) => {
    // add boolean values (since "false" disappears)
    model.forEach((m) => {
      if (m.type === "boolean" && !formData[m.name]) formData[m.name] = false;
    });

    if (!editDoc["id"]) return createDoc(formData);
    else return updateDoc(formData);
  };

  const createDoc = async (formData) => {
    let docRef = await fs.collection(collection).add(formData);
    let newDoc = await fs.collection(collection).doc(docRef.id).get();
    await idCache.addItem(collection, serializeDoc(newDoc));
    setDocs((prev) => [...prev, newDoc]);
    return null;
  };

  const updateDoc = async (formData) => {
    await fs.collection(collection).doc(editDoc.id).update(formData);
    let newDoc = await fs.collection(collection).doc(editDoc.id).get();
    await idCache.updateItem(collection, editDoc.id, serializeDoc(newDoc));
    setDocs((prev) => prev.map((v) => (v.id === editDoc.id ? newDoc : v)));
  };

  const tableModel = tableCells
    ? tableCells.map((c) => model.find((v) => v.name === c))
    : model;
  const HeaderCells = () => (
    <Fragment>
      {tableModel.map((col) => (
        <div className="header-cell d-none d-md-block" key={col.name}>
          <strong>{col.name}</strong>
        </div>
      ))}
      <div className="header-cell d-none d-md-block" />
    </Fragment>
  );

  return (
    <div className="CrudTable">
      <div className="d-flex justify-content-end m-2">
        <Button variant="primary" className="mr-2" onClick={onAddClick}>
          + New
        </Button>
        <Button onClick={() => init(true)}>
          <i className="fas fa-sync" /> Sync
        </Button>
      </div>
      <div
        className="table"
        style={{
          "--num-cols": tableModel.length + 1,
        }}
      >
        <HeaderCells />
        {sortedDocs.map((d) => (
          <Doc
            key={d.id}
            doc={d}
            model={tableModel}
            customRender={customRender}
            setEditDoc={setEditDoc}
            deleteDoc={deleteDoc}
          />
        ))}
      </div>

      <CrudForm
        show={!!editDoc}
        hide={() => setEditDoc(undefined)}
        doc={editDoc}
        onSave={onSave}
        model={model}
        key={editDoc}
      />
    </div>
  );
};

function Doc({ model, customRender, doc, setEditDoc, deleteDoc }) {
  let [deleting, setDeleting] = useState(false);

  const data = doc ? doc.data() : {};
  const handleDelete = async () => {
    if (!window.confirm("are you sure?")) return;
    setDeleting(true);
    deleteDoc(doc.id);
  };

  if (deleting)
    return (
      <div style={{ gridColumn: `1 / span ${model.length + 1}` }}>
        deleting...
      </div>
    );
  return (
    <Fragment>
      {model.map((col) => (
        <Fragment key={col.name}>
          <div className="d-md-none">
            <strong>{col.name}</strong>
          </div>
          <div>
            {(() => {
              if (customRender[col.name]) {
                return customRender[col.name](data[col.name]);
              }
              switch (col.type) {
                case "array":
                  return <ArrayDisplay data={data[col.name]} />;
                case "radio":
                  return data[col.name];
                case "timestamp":
                  return data[col.name] && data[col.name].seconds
                    ? new Date(
                        data[col.name].seconds * 1000
                      ).toLocaleDateString()
                    : "N/A";
                case "boolean":
                  return data[col.name] ? "✔" : "";
                case "checkbox":
                case "date":
                default:
                  return data[col.name];
              }
            })()}
          </div>
        </Fragment>
      ))}
      <div className="d-flex justify-content-end buttons">
        <Button
          size="sm"
          variant="warning"
          className="mr-1"
          onClick={() => setEditDoc(doc)}
        >
          E
        </Button>
        <Button size="sm" variant="danger" onClick={handleDelete}>
          D
        </Button>
      </div>
    </Fragment>
  );
}

// general purpose td
function ArrayDisplay({ data: dataIn }) {
  if (!dataIn) return <Fragment />;
  let data = dataIn instanceof Array ? dataIn : [dataIn];
  return (
    <Fragment>
      {data.map((v, i) => (
        <Badge variant="light" key={i} className="mr-1">
          {v}
        </Badge>
      ))}
    </Fragment>
  );
}

export default styled(CrudTable)`
  color: red;
  ul {
    list-style-type: none;
    color: gold;
  }
`;
