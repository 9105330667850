import { addHours } from "date-fns";

// converts strings like "4:30pm", "2am" into a date object.
const timeToDateObj = (time) => {
  if (typeof time === "undefined") return new Date();
  let date = new Date();
  let [, h, m, pm] = time.match(/(\d+):?(\d*)(p?)/);
  date.setHours(h);
  if (pm === "p" && h !== "12") date = addHours(date, 12);
  date.setMinutes(m);
  return date;
};

const yearToGrade = (yr) => {
  if (!yr || isNaN(yr)) return 0;
  yr = parseInt(yr, 10); // in case it's a string

  let grade = 12 - (yr - new Date().getFullYear());
  if (new Date().getMonth() > 6) grade++;
  if (grade > 12) return 0;

  return grade;
};

export { timeToDateObj, yearToGrade };
