import React from 'react';

export default function Exercise() {
  return <div>-</div>
}

// import React, { useState } from "react";
// import Editor from "react-simple-code-editor";
// import Prism from 'prismjs'
// import { highlight, languages } from "prismjs/components/prism-core";
// import "prismjs/components/prism-clike";
// import "prismjs/components/prism-javascript";

// const java_code = `public class Main {
//   public static void main(String[] args) {
//     System.out.println("hello world");
//   }
// }`
// const py_code = `print("hello world")`
// const js_code = `console.log("hello world")`

// export default function Exercise() {
//   const [code, setCode] = useState(java_code);
//   return (
//     <div className="App">
//       <h1>Hello CodeSandbox</h1>
//       <h2>Start editing to see some magic happen!</h2>
//       <Editor
//         value={code}
//         onValueChange={(code) => setCode(code)}
//         highlight={(code) => highlight(code, languages.js)}
//         padding={10}
//         style={{
//           fontFamily: '"Fira code", "Fira Mono", monospace',
//           fontSize: 12
//         }}
//       />{" "}
//     </div>
//   );
// }


// // function Floater({ addSubmission, submissions }) {
// //   let [open, setOpen] = useState(false);
// //   let [code, setCode] = useState(java_code);
// //   let [lang, setLang] = useState('clike');
// //   let [highlighter, setHL] = useState(languages.clike)
// //   let [submitting, setSB] = useState(false);

// //   async function onSubmit(e) {
// //     e.preventDefault();
// //     setSB(true);
// //     await addSubmission(Object.fromEntries(new FormData(e.target)));
// //     setSB(false);
// //     setOpen(false);
// //   }

// //   useEffect(() => {
// //     switch (lang) {
// //       case 'clike': setHL(languages.clike); setCode(java_code); break;
// //       case 'js': setHL(languages.js); setCode(js_code); break;
// //       case 'py':
// //       default: setCode(py_code); setHL(languages.py); break;
// //     }
// //   }, [lang]);

// //   return (
// //     <>
// //       <div className="floater" onClick={() => setOpen(true)}>
// //         <FaPencilAlt />
// //       </div>
// //       {
// //         open && (
// //           <Modal show={open} onHide={() => setOpen(false)} size="lg">
// //             <Modal.Header closeButton>In-Class Code Submission</Modal.Header>
// //             <Modal.Body>
// //               <Form onSubmit={onSubmit}>
// //                 <FormGroup>
// //                   <label>Exercise Name</label>
// //                   <FormControl name="name" required />
// //                 </FormGroup>
// //                 <FormGroup>
// //                   <ToggleButtonGroup name="type" onChange={setLang} defaultValue="clike">
// //                     <ToggleButton variant="outline-secondary" value="clike">Java/C++</ToggleButton>
// //                     <ToggleButton variant="outline-secondary" value="js">Javascript</ToggleButton>
// //                     <ToggleButton variant="outline-secondary" value="py">Python</ToggleButton>
// //                   </ToggleButtonGroup>
// //                 </FormGroup>
// //                 <FormGroup>
// //                   <label>code</label>
// //                 </FormGroup>
// //                 <Button type="submit" disabled={submitting} variant={submitting ? "danger" : "primary"}>Submit</Button>
// //               </Form>
// //             </Modal.Body>
// //           </Modal>
// //         )
// //       }
// //     </>
// //   )
// // }