import React, { Fragment, useRef } from "react";
import { Container } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import * as models from "../components/crud-table/models/common-models";
import CrudTable from "../components/crud-table/table";
import { fstore } from "../utils/fb";
import Select from "react-select";

export default function Crud() {
  const selectRef = useRef(undefined);
  const history = useHistory();
  const params = useParams();

  const modelObj = models[params.model + "Model"];

  return (
    <Container className="mt-3">
      <Select
        ref={selectRef}
        options={Object.keys(models).map((m) => ({
          value: m,
          label: m.replace("Model", ""),
        }))}
        onChange={(v) => history.push(`/admin/crud/${v.label}`)}
      />
      <hr />
      {modelObj && (
        <Fragment>
          <h3>{modelObj.collection}</h3>
          <CrudTable key={modelObj.collection} fs={fstore} {...modelObj} />
        </Fragment>
      )}
    </Container>
  );
}
