import React, { useCallback, useEffect, useState } from "react";
import { Toast } from "react-bootstrap";
import { BrowserRouter } from "react-router-dom";
import Loading from "./components/loading";
import { LoginModal } from "./components/login/login-modal";
import { Main } from "./Main";
import { User } from "./models/user-model";
import Signin from "./pages/signin/signin";
import "./style.scss";
import { fauth, fstore } from "./utils/fb";
import SiteContext from "./utils/site-context";

const SESSION_DATA_NAME = "session-data";

export default function App() {

  //region site contexts
  const [user, setUser] = useState(new User());
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([
    // { id: 1, text: "hello", title: "??" },
  ]);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [adminMode, setAdminMode] = useState(
    localStorage.getItem("adminMode") === "true"
  );
  //endregion

  const initSession = useCallback(() => {
    const u = fauth.currentUser;
    let session = {};
    session.uid = u.uid;
    session.email = u.email;
    session.displayName = u.displayName || u.email.split("@")[0].split(".").join(" ");
    session.isParent = u.email && u.email.includes("parent");
    session.isStudent = u.email && u.email.includes("student");
    session.isAdmin = false;
    session.isSuperAdmin = false;

    // extra user doc
    let p1 = fstore.collection("users").doc(u.uid).get().then(userDoc => {
      if (userDoc.exists) {
        return userDoc.data();
      } else {
        fstore.collection("users").doc(u.uid).set({
          created_at: new Date(),
          displayName: session.displayName,
          email: session.email,
        });
        return {};
      }
    })
      .catch(e => console.log("p1", e));

    // extra student data (for offline students)
    let p2 = Promise.resolve(undefined);

    // if (typeof u.email === 'string' && (u.email.endsWith("@btreecode-parent.com") || u.email.endsWith("@btreecode-student.com"))) {
    //   // legacy
    //   p2 = fstore.collection("students").where("login_id", "==", u.email.split("@")[0]).get()
    //     .then(s => s.docs)
    //     .then(docs => {
    //       if (docs.length === 1) return docs[0];
    //       else return undefined;
    //     })
    //     .catch(e => console.log("p2", e))
    if (typeof u.email === 'string') {
      let emailRegex = /btreecode\+(parent|student)\.([^@]+)@gmail\.com/g;
      let result = emailRegex.exec(u.email);
      
      if (result && result[2]) {
        p2 = fstore.collection("students").where("login_id2", "==", result[2]).get()
          .then(s => s.docs)
          .then(docs => {
            if (docs.length === 1) return docs[0];
            else return undefined;
          })
          .catch(e => console.log("p2", e))
      }
    }

    Promise.all([p1, p2])
      .then(([extraUserData, student]) => {
        let user = { ...session, ...extraUserData, student };
        window.localStorage.setItem(SESSION_DATA_NAME, "true");
        setLoading(false);
        setUser(user);
      }).catch(e => console.error(e));

  }, []);

  useEffect(() => {
    const session_data = window.localStorage.getItem(SESSION_DATA_NAME);
    if (!session_data) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fauth.getRedirectResult().catch((err) =>
      addNotification({
        text: err.message,
        type: "danger",
        seconds: 10,
        title: "LoginError",
      })
    );

    const unsub2 = fauth.onAuthStateChanged(async (u) => {
      if (!u) {
        window.localStorage.clear();
        setUser({ isGuest: true });
        setLoading(false);
      } else {
        initSession();
      }
    });

    return () => {
      unsub2();
    };
  }, [initSession]);

  useEffect(() => localStorage.setItem("adminMode", adminMode), [adminMode]);

  function hideNotification(id) {
    setNotifications((prev) =>
      prev.map((v) => (v.id === id ? { ...v, hide: true } : v))
    );
  }

  function addNotification({
    text,
    title = "Notification",
    seconds = 5,
    type = "info",
  }) {
    const id = new Date().getTime();
    setNotifications((prev) => [...prev, { id, text, title, type, seconds }]);
    setTimeout(() => {
      setNotifications((prev) => prev.filter((v) => v.id !== id));
    }, (seconds + 2) * 1000);
  }

  return (
    <BrowserRouter>
      <SiteContext.Provider
        value={{
          addNotification, hideNotification, setShowLoginModal,
          adminMode, setAdminMode, user
        }}
      >
        {(() => {
          if (loading) return <Loading className="fullscreen-centered" msg="Retrieving session data" />;
          else if (user.isGuest) return <Signin />
          else return <Main />;
        })()}

        {/*notifications*/}
        <Notification
          notifications={notifications}
          hideNotification={hideNotification}
        />
        {/*  login modal */}
        <LoginModal
          setShowLoginModal={setShowLoginModal}
          showLoginModal={showLoginModal}
        />
      </SiteContext.Provider>
    </BrowserRouter>
  );
}

function Notification({ notifications, hideNotification }) {
  return (
    <div
      style={{
        position: "fixed",
        top: 50,
        right: 50,
      }}
    >
      {notifications.map((n) => (
        <Toast
          key={n.id}
          onClose={() => hideNotification(n.id)}
          show={!n.hide}
          delay={n.seconds * 1000}
          autohide={true}
        >
          <Toast.Header>
            <div
              style={{ height: "20px", width: "20px" }}
              className={`rounded mr-2 bg-${n.type}`}
              alt=""
            />
            <strong className="mr-auto">{n.title}</strong>
            {/*<small>just now</small>*/}
          </Toast.Header>
          <Toast.Body>
            {n.text} {n.hide ? "hiding..." : ""}
          </Toast.Body>
        </Toast>
      ))}
    </div>
  );
}

