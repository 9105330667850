import React, { useContext, useState, useEffect } from "react";
import { Button, Col, Form, FormControl, FormGroup, FormLabel, InputGroup, Row } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ContainerWithHeader from "../components/ContainerWithHeader";
import { fauth } from "../utils/fb";
import SiteContext from "../utils/site-context";

function MemberProfile() {
  const { addNotification, user } = useContext(SiteContext);
  const [showP, setShowP] = useState(false);
  const [changingP, setChangingP] = useState(false);
  const [err, setErr] = useState({});

  useEffect(() => {
    if (user) {
      console.log(user)
    }
  }, [user])

  function onPasswordChange(e) {
    e.preventDefault();
    e.persist();
    let { cpassword, npassword } = e.target;
    setChangingP(true);
    setErr({});

    fauth.signInWithEmailAndPassword(user.email, cpassword.value)
      .then(() => {
        fauth.currentUser.updatePassword(npassword.value)
          .then(() => {
            addNotification({ text: "Password has been changed successfully 😀" });
            e.target.reset();
          })
          .catch(e => setErr({ npassword: e.message }))
          .finally(() => setChangingP(false));
      })
      .catch(e => {
        setErr({ cpassword: e.message })
      })
      .finally(() => setChangingP(false));
  }

  return (
    <ContainerWithHeader title={"Member Dashboard"}>
      <Form onSubmit={onPasswordChange}>
        <FormGroup as={Row}>
          <FormLabel column sm="2">Username</FormLabel>
          <Col sm="10">
            <FormControl plaintext readOnly defaultValue={user.student && user.student.data().login_id2} />
          </Col>
        </FormGroup>

        <FormGroup as={Row}>
          <FormLabel column sm="2">Current Password</FormLabel>
          <Col sm="10" className="d-flex align-items-center">
            <InputGroup>
              <FormControl name="cpassword" type={showP ? 'text' : 'password'} placeholder=""
                isInvalid={err.cpassword}
              />
              <InputGroup.Append>
                <Button variant="outline-secondary" onClick={() => setShowP(v => !v)}>
                  {showP ? <FaEyeSlash /> : <FaEye />}
                </Button>
              </InputGroup.Append>
              {err.cpassword &&
                <Form.Control.Feedback type="invalid">{err.cpassword}</Form.Control.Feedback>
              }
            </InputGroup>
          </Col>
        </FormGroup>

        <FormGroup as={Row}>
          <FormLabel column sm="2">New  Password</FormLabel>
          <Col sm="10" className="d-flex align-items-center">
            <InputGroup>
              <FormControl name="npassword" type={showP ? 'text' : 'password'} placeholder=""
                isInvalid={err.npassword}
              />
              <InputGroup.Append>
                <Button variant="outline-secondary" onClick={() => setShowP(v => !v)}>
                  {showP ? <FaEyeSlash /> : <FaEye />}
                </Button>
              </InputGroup.Append>
              {err.npassword &&
                <Form.Control.Feedback type="invalid">{err.npassword}</Form.Control.Feedback>
              }
            </InputGroup>
          </Col>
        </FormGroup>
        <Button variant="primary" type="submit" disabled={changingP}>Change Password {changingP && "...."}</Button>
      </Form>
    </ContainerWithHeader>
  );
}

export default MemberProfile;
