import { fstore } from "./fb";

// adds "number" and "boolean" type checking to window.prompt
const dkprompt = function (defValue) {
  let value = window.prompt("new value", defValue);
  if (value === null) return null;
  if (!isNaN(value)) value = parseInt(value, 10);
  else if (value === "true" || value === "false") value = value === "true";
  return value;
}

export const updateFieldPrompt = async ({ collectionName, doc, field, isDetail = false }) => {
  const value = dkprompt(isDetail ? doc.data().detail[field] : doc.data()[field]);
  if (value === null) return false;

  if (isDetail)
    await fstore.collection(collectionName).doc(doc.id).update({ detail: { ...doc.data().detail, [field]: value } });
  else
    await fstore.collection(collectionName).doc(doc.id).update({ [field]: value });
  return await fstore.collection(collectionName).doc(doc.id).get();
}

export const updateField = async ({ collectionName, doc, field, value, isDetail = false }) => {
  if (isDetail) {
    await fstore.collection(collectionName).doc(doc.id).update({ detail: { ...doc.data().detail, [field]: value } });
  }
  else
    await fstore.collection(collectionName).doc(doc.id).update({ [field]: value });

  return await fstore.collection(collectionName).doc(doc.id).get();
}