import React, { Fragment, useContext } from "react";
import { Card } from "react-bootstrap";
import ContainerWithHeader from "../../components/ContainerWithHeader";
import StudentClassRecords from "../../student/components/student-class-records";
import SiteContext from "../../utils/site-context";
import { AlgoTable } from "../algo/algo-index";
import "./student-dashboard.scss";
// import { LinkContainer } from 'react-router-bootstrap'

export default function StudentDashboard() {
  const { user } = useContext(SiteContext);

  if (!user) return <Fragment />
  if (user.student === null) return <div>no student info</div>

  return (
    <ContainerWithHeader title="Dashboard" contentClassName="Student-Dashboard">
      {/* <ZoomLinks /> */}
      {/* <MyHomework user={user} /> */}

      <Card className="mt-3 mb-3">
        <Card.Header>Code Submissions (Last 7 days)</Card.Header>
        <Card.Body>
          <AlgoTable student={user.student} />
        </Card.Body>
      </Card>

      {
        user.isParent ? <MyClasses user={user} /> : '😶 Hello'
      }

      {/* <MyQuestions user={user} /> */}
    </ContainerWithHeader>
  );
}

/*

function MyQuestions({ user }) {
  let [ar, setAr] = useState([]);

  useEffect(() => {
    fstore.collection("questions").where("uid", "==", user.uid).orderBy("postedDate", "desc").get().then(s => setAr(s.docs));
  }, [user.uid])

  return (
    <>
      <h4 className="mt-5">My Questions</h4>
      <div className="text-muted">Questions asked over the last 30 days</div>

      <QuestionList ar={ar} />
    </>
  )
}

function MyHomework({ user }) {
  return (
    <>
      <h4 className="mt-5">Homework Tracker</h4>
      <div className="text-muted mb-3">In-class and Homework exercises submitted</div>
      <Homework user={user} />
    </>
  )
}

function ZoomLinks() {
  return (
    <div className="d-flex justify-content-end align-items-center">
      <Badge>Meeting Links</Badge>
      {
        [
          ["Daniel", "https://us02web.zoom.us/s/9489011552?pwd=TzZiSHJvUXAvSmR5TFBBeitYczlyUT09"],
          ["David", "https://us02web.zoom.us/s/9489011552?pwd=TzZiSHJvUXAvSmR5TFBBeitYczlyUT09"],
          ["Matthew", "https://us02web.zoom.us/s/9489011552?pwd=TzZiSHJvUXAvSmR5TFBBeitYczlyUT09"],
        ].map(v => (
          <a href={v[1]} target="_blank" key={v[0]} rel="noopener noreferrer">
            <Button size="sm" className="ml-2" variant="outline-primary">
              {v[0]}
              <img src="https://us02st1.zoom.us/static/94107/image/new/ZoomLogo.png" alt="" className="ml-2" style={{ height: '0.5em' }} />
            </Button>
          </a>
        ))
      }
    </div>
  )
}
*/

function MyClasses({ user }) {

  return (
    <div className="my-classes">
      <StudentClassRecords student={user.student} />
    </div>
  )
}

