import React from "react";

const pipelines = [
  {
    name: "Youth (Grades 3-6)",
    desc: "Tailored for elementary school students (Grade 4-6)",
    headerClass: "bg-primary text-white",
    courses: [
      {
        id: 'cAgOjxUPKHDA2kt00dt5',
        name: "Scratch",
        desc: "Learn through playing",
        duration: "3 months",
        img: "https://i.imgur.com/zxDduhZ.png",
        req: undefined
      },
      {
        id: "cTaHLMhIoQoksFoRIod4",
        name: "Scratch 2",
        desc: "Challenging algorithm using Scratch",
        duration: "3 months",
        img: "https://i.imgur.com/zxDduhZ.png",
        req: "Scratch"
      },
      {
        id: "CFd4AQ3wo29XMkPqIwA7",
        name: "Python",
        desc: "Learn Python fundamentals",
        duration: "3 - 6 months",
        img: "https://i.imgur.com/ENwCcwr.png",
        req: undefined
      },
      {
        id: "OswdjA2C2WKQtawrS4Q9",
        name: "Py Games",
        desc: "Game development using Python and Piglet game engine",
        duration: "3 - 6 months",
        img: "https://i.imgur.com/3bo3vnj.png",
        req: "Python"
      },
      {
        id: "G9wkimzIIQ2XeWnt8srg",
        name: "Youth Algorithms",
        desc:
          "Learn algorithms through puzzle solving. Prepares for future competitive programming.",
        duration: "3 - 6 months",
        img: "https://i.imgur.com/ENwCcwr.png",
        req: "Py Games"
      },
    ],
  },
  {
    name: "Languages for Beginners",
    desc: <>Start your coding experience here. For beginners, we would recommend <b>Python</b> as your first language</>,
    courses: [
      {
        id: "CFd4AQ3wo29XMkPqIwA7",
        name: "Python",
        desc: "Learn Python fundamentals",
        duration: "3 months",
        img: "https://i.imgur.com/ENwCcwr.png",
        req: undefined
      },
      {
        id: "ivuvjsOZlVuF0RKVGdJT",
        name: "Java",
        desc: "Learn Java for emphasis in topics: OOP, AP CS A",
        duration: "3 months",
        img: "https://upload.wikimedia.org/wikipedia/en/thumb/3/30/Java_programming_language_logo.svg/212px-Java_programming_language_logo.svg.png",
        req: undefined
      },
      {
        id: "MUSWgaf8U8qFrU0J2z5K",
        name: "C++",
        desc: "Learn C++ language",
        duration: "4 months",
        img:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/ISO_C%2B%2B_Logo.svg/225px-ISO_C%2B%2B_Logo.svg.png",
        req: undefined
      },
      {
        id: "UyWnpFbcgKNCs4tX9Lrs",
        name: "Javascript",
        desc: "Learn JavaScript for Portfolio and app development",
        duration: "2 months",
        img:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/600px-JavaScript-logo.png",
      }
    ]
  },
  {
    name: "App Development (Portfolio)",
    desc:
      "Create a modern web and mobile applications. Build a portfolio. Enter programming competitions, hackathons, and begin a startup entrepreneurship",
    courses: [
      {
        id: "i7uVlg8b2Lx7wr2Ld7j1",
        name: "Web 101",
        desc:
          "Build a profressional looking website using HTML, CSS, and Bootstrap",
        duration: "6 weeks",
        img: "https://www.w3.org/html/logo/downloads/HTML5_Badge.svg",

      },
      {
        id: "WxAOpafM1lgUw2g16vv1",
        name: "Web App",
        desc: "Build your own web application like Instagram using Javascript and jQuery",
        duration: "2 months",
        img:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/600px-JavaScript-logo.png",
        req: "Web 101"
      },
      {
        id: "mkutunNrMPjJ8hf9NhAk",
        name: "Web App 2",
        desc:
          `Build more advanced apps by utilizing back-end services: Google Firebase, Netlify and Github.
          `,
        img:
          "https://firebase.google.com/downloads/brand-guidelines/SVG/logo-logomark.svg",
        duration: "2 months",
        req: "Web App"
      },
      {
        id: "5SVTiKum3FwEs02Zkpti",
        name: "React",
        desc: 'Build a professional web and mobile application using React and React Native',
        img:
          "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg",
        duration: "3 months",
        req: "Web App 2",
      },
      {
        id: "SeVv7PRnu5kUYkQ4Eeca",
        name: "App Bootcamp",
        desc:
          "Learn everything else you need to become a developer (or start a business). Topics coverd: linux, API servers, sockets, git and more",
        duration: "6 months",
        img: "https://i.imgur.com/fCcqFZ1.png",
        req: "React"
      },
    ],
  },
  {
    name: "Competitive Programming",
    desc: `Covers algorithms needed to participate in various competitions: USACO Bronze, HSPC, Google CodeJam and more. 
      This course also covers AP CS A and the first two years of college curriculum.
      Strong fundamentals in (any) one programming language is required.
    `,
    courses: [
      {
        id: "5GAnlyrh1rX8heDooVuf",
        name: "Array Algorithms",
        desc: "Array based problem solving",
        duration: "2 months",
        img: "https://i.imgur.com/ENwCcwr.png",

      },
      {
        id: "seBOyp6w9aBVcvTODApd",
        name: "USACO Bronze",
        desc: "Data structures and basic algorithms required for USACO Bronze",
        duration: "5 months",
        img: "🥉",
        req: "Array Algorithms"
      },
      {
        id: 'CVEjGSKa8IMbg1YJWCQ5',
        name: "USACO Silver",
        desc: "Data structures, complexity, and graph theory",
        duration: "5 months",
        img: '🥈',
        req: 'USACO Bronze'
      },
      {
        id: "gHWn5TtzuxEWkchM8D0P",
        name: "USACO Gold",
        desc: "USACO Gold problem solving, debugging and tips.",
        duration: "5 months",
        img: "🥇",
        req: 'USACO Silver'
      },
    ],
  },
  {
    name: "Game Development (Portfolio)",
    desc: "Build a full 3D desktop game using Unity engine",
    courses: [
      {
        id: "B69kkO0IRYPlBhKTjLow",
        name: "Unity 2D",
        desc: "Learn the basics of game development and create exciting 2D games using Unity",
        duration: "3 months",
        img:
          "https://upload.wikimedia.org/wikipedia/commons/1/19/Unity_Technologies_logo.svg",

      },
      {
        name: "Unity 3D",
        desc:
          "Create more advanced games in 3D",
        duration: "3 months",
        img:
          "https://upload.wikimedia.org/wikipedia/commons/1/19/Unity_Technologies_logo.svg",
        req: "Unity 2D"
      },
      {
        name: "Advanced Unity",
        desc:
          "Advanced and more practical game development. 3D FPS, multiplayer, stage generation, math & physics, game design, balancing",
        duration: "self-paced",
        img:
          "https://upload.wikimedia.org/wikipedia/commons/1/19/Unity_Technologies_logo.svg",
        req: "Unity 3D"
      },
    ],
  },
];

pipelines.forEach((p) => {
  p.courses.forEach((c) => (c.cid = c.name.replace(" ", "-").toLowerCase()));
});

function getCourseByCid(cid) {
  let ret;

  pipelines.forEach((p) => {
    p.courses.forEach((c) => {
      if (c.id === cid) ret = c;
    });
  });
  return ret;
}

export { pipelines, getCourseByCid };
